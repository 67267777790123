import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UploadImageComponent } from '../upload-image/upload-image.component';
import { Vendor } from 'src/app/models/vendor.model';
import { Location } from 'src/app/models/offer.model';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { LocationsService } from '../../services/locations.service';

@Component({
  selector: 'app-vendor-form',
  templateUrl: './vendor-form.component.html',
  styleUrls: ['./vendor-form.component.scss']
})
export class VendorFormComponent implements OnInit {

  @Input() isEdit = false;
  @Input() existingVendor!: Vendor;
  @Output() createVendor = new EventEmitter<Vendor>();
  @Output() updateVendor = new EventEmitter<Vendor>();
  @Output() startLoading = new EventEmitter<void>();
  @Output() stopLoading = new EventEmitter<void>();
  @Output() goBack = new EventEmitter<void>();

  @ViewChild('uploadImage')
  private uploadImage!: UploadImageComponent;

  @ViewChild('locationInput')
  locationInput!: ElementRef<HTMLInputElement>;

  vendorForm!: FormGroup;
  logoUrl = '';
  filteredLocations!: Observable<Location[]>;
  addedLocations: Location[] = [];

  constructor(private formBuilder: FormBuilder, private locationService : LocationsService) { }

  ngOnInit(): void {
    if (this.isEdit) {
      this.vendorForm = this.formBuilder.group({
        'name': new FormControl(this.existingVendor.name, Validators.required),
        'name_ar': new FormControl(this.existingVendor.name_ar, Validators.required),
        'description': new FormControl(this.existingVendor.description),
        'description_ar': new FormControl(this.existingVendor.description_ar),
        'telephones': new FormArray([]),
        'mobiles': new FormArray([]),
        'emails': new FormArray([]),
        'website': new FormControl(this.existingVendor.website),
        'smeName': new FormControl(this.existingVendor.smeName),
        'smeEmail': new FormControl(this.existingVendor.smeEmail),
        'smePhone': new FormControl(this.existingVendor.smePhone),
        'locations': new FormControl(""),
      });
      this.logoUrl = this.existingVendor.logo ? this.existingVendor.logo : '';
      this.addedLocations = this.existingVendor.locations ? this.existingVendor.locations : [];

      // populate FormArray fields with data - or display one empty field
      if (this.existingVendor.telephone && this.existingVendor.telephone.length > 0) {
        for (var tele of this.existingVendor.telephone) {
          this.telephones.push(new FormControl(tele));
        }
      } else {
        this.telephones.push(new FormControl(""));
      }
      // populate FormArray fields with data - or display one empty field
      if (this.existingVendor.mobile && this.existingVendor.mobile.length > 0) {
        for (var mob of this.existingVendor.mobile) {
          this.mobiles.push(new FormControl(mob));
        }
      } else {
        this.mobiles.push(new FormControl(""));
      }
      // populate FormArray fields with data - or display one empty field
      if (this.existingVendor.email && this.existingVendor.email.length > 0) {
        for (var em of this.existingVendor.email) {
          this.emails.push(new FormControl(em));
        }
      } else {
        this.emails.push(new FormControl(""));
      }

    } else {
      this.vendorForm = this.formBuilder.group({
        'name': new FormControl("", Validators.required),
        'name_ar': new FormControl("", Validators.required),
        'description': new FormControl(""),
        'description_ar': new FormControl(""),
        'telephones': new FormArray([new FormControl("")]),
        'mobiles': new FormArray([new FormControl("")]),
        'emails': new FormArray([new FormControl("")]),
        'website': new FormControl(""),
        'smeName': new FormControl(""),
        'smeEmail': new FormControl(""),
        'smePhone': new FormControl(""),
        'locations': new FormControl(""),
      });
      this.logoUrl = '';
    }

    this.filteredLocations = this.vendorForm.get('locations')!.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      switchMap((value: string) => this.locationService.getLocationsData(value)),
      map(data => data),
    );
  }

    //removing image from vendor
    removeImage(){
      this.logoUrl= '';
    }

  selectedLocation(event: MatAutocompleteSelectedEvent): void {
    this.addedLocations.push(event.option.value);
    this.locationInput.nativeElement.value = '';
    this.vendorForm.get('locations')?.setValue('');
  }
  removeLocation(location: Location): void {
    const index = this.addedLocations.indexOf(location);

    if (index >= 0) {
      this.addedLocations.splice(index, 1);
    }
  }

  

  //to select the values of vendor locations in edit-form [dropdownlist]
  compareObjects(o1: any, o2: any): boolean {
    return o1.city === o2.city && o1._id === o2._id;
  }

  get telephones() {
    return this.vendorForm.get('telephones') as FormArray;
  }
  addTelephone() { // add field only if there are no empty fields
    var canAdd = true;
    for (let i = 0; i < this.telephones.length; i++) {
      if (this.telephones.at(i).value == "") {
        canAdd = false;
      }
    }
    if (canAdd)
      this.telephones.push(new FormControl(""));
  }
  removeTelephoneAt(index: number) {
    this.telephones.removeAt(index);
  }

  get mobiles() {
    return this.vendorForm.get('mobiles') as FormArray;
  }
  addMobile() { // add field only if there are no empty fields
    var canAdd = true;
    for (let i = 0; i < this.mobiles.length; i++) {
      if (this.mobiles.at(i).value == "") {
        canAdd = false;
      }
    }
    if (canAdd)
      this.mobiles.push(new FormControl(""));
  }
  removeMobileAt(index: number) {
    this.mobiles.removeAt(index);
  }

  get emails() {
    return this.vendorForm.get('emails') as FormArray;
  }
  addEmail() { // add field only if there are no empty fields
    var canAdd = true;
    for (let i = 0; i < this.emails.length; i++) {
      if (this.emails.at(i).value == "") {
        canAdd = false;
      }
    }
    if (canAdd)
      this.emails.push(new FormControl(""));
  }
  removeEmailAt(index: number) {
    this.emails.removeAt(index);
  }


  async submitForm() {
    this.startLoading.emit();
    const logo = await this.uploadImage.triggerPost().toPromise();
    if (logo) {
      this.logoUrl = logo;
    }
    this.stopLoading.emit();

    //clearing arrays from empty records before sending to db
    for (let i = 0; i < this.telephones.length; i++) {
      if (this.telephones.at(i).value == "") {
        this.removeTelephoneAt(i);
      }
    }
    for (let i = 0; i < this.mobiles.length; i++) {
      if (this.mobiles.at(i).value == "") {
        this.removeMobileAt(i);
      }
    }
    for (let i = 0; i < this.emails.length; i++) {
      if (this.emails.at(i).value == "") {
        this.removeEmailAt(i);
      }
    }

    const newVendor: Vendor = {
      name: this.vendorForm.get("name")?.value,
      name_ar: this.vendorForm.get("name_ar")?.value,
      description: this.vendorForm.get("description")?.value,
      description_ar: this.vendorForm.get("description_ar")?.value,
      telephone: this.vendorForm.get("telephones")?.value,
      mobile: this.vendorForm.get("mobiles")?.value,
      email: this.vendorForm.get("emails")?.value,
      website: this.vendorForm.get("website")?.value,
      smeName: this.vendorForm.get("smeName")?.value,
      smeEmail: this.vendorForm.get("smeEmail")?.value,
      smePhone: this.vendorForm.get("smePhone")?.value,
      locations: this.addedLocations,
      logo: this.logoUrl,
    }

    if (this.isEdit) {
      newVendor._id = this.existingVendor._id;
      this.updateVendor.emit(newVendor);
    } else {
      this.createVendor.emit(newVendor);
    }
  }

}
