import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MomentUtcDateAdapter } from './moment-utc-date-adapter';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgOtpInputModule } from 'ng-otp-input';
import { materialComponents } from './material.module';
import { ContainerComponent } from './pages/container/container.component';
import { LoginComponent } from './pages/login/login.component';
import { VendorsTableComponent } from './components/vendors-table/vendors-table.component';
import { CategoriesTableComponent } from './components/categories-table/categories-table.component';
import { OffersTableComponent } from './components/offers-table/offers-table.component';
import { LocationTableComponent } from './components/location-table/location-table.component'; // add by fawzi
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { ViewCategoryComponent } from './components/view-category/view-category.component';
import { ViewDialogComponent } from './components/view-dialog/view-dialog.component';
import { ViewVendorComponent } from './components/view-vendor/view-vendor.component';
import { ViewOfferComponent } from './components/view-offer/view-offer.component';
import { CategoryFormComponent } from './components/category-form/category-form.component';
import { VendorFormComponent } from './components/vendor-form/vendor-form.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { OfferFormComponent } from './components/offer-form/offer-form.component';
import { FeedbackTableComponent } from './components/feedback-table/feedback-table.component';
import { VendorFormDialogComponent } from './components/vendor-form-dialog/vendor-form-dialog.component';
import { NewsFormComponent } from './components/news-form/news-form.component';
import { NewsTableComponent } from './components/news-table/news-table.component';
import { ViewNewsComponent } from './components/view-news/view-news.component';
import { LocationFormComponent } from './components/location-form/location-form.component';
import { OtpComponent } from './pages/otp/otp.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UsersTableComponent } from './components/users-table/users-table.component';
import { ViewUserComponent } from './components/view-user/view-user.component';
import { VersionFormComponent } from './components/version-form/version-form.component';
import { VersionsTableComponent } from './components/versions-table/versions-table.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    VendorsTableComponent,
    CategoriesTableComponent,
    ContainerComponent,
    OffersTableComponent,
    LocationTableComponent, // add by fawzi
    UploadImageComponent,
    ViewCategoryComponent,
    ViewDialogComponent,
    ViewVendorComponent,
    ViewOfferComponent,
    CategoryFormComponent,
    VendorFormComponent,
    ConfirmDialogComponent,
    OfferFormComponent,
    FeedbackTableComponent,
    VendorFormDialogComponent,
    NewsFormComponent,
    NewsTableComponent,
    ViewNewsComponent,
    LocationFormComponent,
    OtpComponent,
    UserFormComponent,
    UsersTableComponent,
    ViewUserComponent,
    VersionFormComponent,
    VersionsTableComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxDropzoneModule,
    NgOtpInputModule,
    MatMomentDateModule,
    materialComponents
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [ViewDialogComponent, ConfirmDialogComponent, VendorFormDialogComponent]
})
export class AppModule { }
