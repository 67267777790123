import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Version } from 'src/app/models/version.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-versions-table',
  templateUrl: './versions-table.component.html',
  styleUrls: ['./versions-table.component.scss']
})
export class VersionsTableComponent implements OnInit {
  @Input() versions: Version[] = [];
  @Input() searchTerm = '';
  @Output() viewVersion =new EventEmitter<Version>();
  @Output() editVersion = new EventEmitter<Version>();
  @Output() deleteVersion = new EventEmitter<Version>();
  
  displayedColumns: string[] = ['Channel', 'Version Number', 'Force Update','Enable Localization', 'Actions'];

  constructor(private matDialog: MatDialog) { }

  ngOnInit(): void {
  }

  getFilteredList(): Version[] {
    return this.versions.filter((ver) => {
      return ver.channel.toLowerCase().includes(this.searchTerm.toLowerCase());
    });
  }
  
  editVersionClicked(category: Version) {
    this.editVersion.emit(category);
  }

  viewVersionData(category: Version) {
    this.viewVersion.emit(category);
  }
  
  deleteVersionClicked(category: Version) {
    const dialogConfig: MatDialogConfig = {
      data: {
        message: "Are you sure you want to delete?"
      }
    };
    this.matDialog.open(ConfirmDialogComponent, dialogConfig).afterClosed().subscribe(
      result => {
        if (result) {
          this.deleteVersion.emit(category);
        } 
      }
    );
  }
}
