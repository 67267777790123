import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Feedback } from '../models/feedback.model';
import { HttpUtilsService } from './http-utils.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient, private utils: HttpUtilsService) { }

  getFeedbackData(searchTerm: string = '', skip: number = 0): Observable<Feedback[]> {
    return this.http.get<Feedback[]>('/api/feedback?query=' + searchTerm + '&skip=' + skip, this.utils.httpOptions);
  }

  getFeedbackCsv(): Observable<{csv: string}> {
    return this.http.get<{csv: string}>('/api/feedback-csv', this.utils.httpOptions);
  }

  updateFeedback(feedback: Feedback) {
    return this.http.post<Feedback>('/api/update-feedback/' + feedback._id, feedback, this.utils.httpOptions);
  }
}