<div *ngIf="isLoading" class="loading"></div>
<mat-sidenav-container class="container">
    <mat-sidenav class="sidenav" opened mode="side">
        <div class="logo">
            <img class="logo-icon" alt="Offers Logo (icon)" src="../../../assets/images/logo_icon.svg">
            <img class="logo-text" alt="Offers Logo (text)" src="../../../assets/images/logo_text.svg">
        </div>

        <button mat-menu-item class="nav-button" (click)="openOfferList()"
            [class.clicked]="showOfferTable">Offers</button>
        <button mat-menu-item class="nav-button" (click)="openVendorList()"
            [class.clicked]="showVendorTable">Vendors</button>
        <button mat-menu-item *ngIf="isAdmin()" class="nav-button" (click)="openCategoryList()"
            [class.clicked]="showCategoryTable">Categories</button>
        <button mat-menu-item class="nav-button" (click)="openFeedbackList()"
            [class.clicked]="showFeedbackTable">Feedback</button>
        <button mat-menu-item class="nav-button" (click)="openNewsList()" [class.clicked]="showNewsTable">News</button>
        <button mat-menu-item *ngIf="isAdmin()" class="nav-button" (click)="openUsersList()"
            [class.clicked]="showUsersTable">Users</button>
        <button mat-menu-item *ngIf="isAdmin()" class="nav-button" (click)="openLocationList()"
            [class.clicked]="showLocationTable">Locations</button>

        <button mat-menu-item *ngIf="isAdmin()" class="nav-button" (click)="openVersionsList()"
            [class.clicked]="showVersionsTable">Versions</button>
    </mat-sidenav>
    <mat-sidenav-content class="container-content">
        <div class="page-header">
            <mat-toolbar>
                <div class="title">{{ title }}</div>

                <form [formGroup]="searchForm" (ngSubmit)="submitSearch()">
                    <div class="search-feild">
                        <mat-form-field appearance="fill">
                            <input type="text" matInput formControlName="search" placeholder="Search">
                            <mat-icon matSuffix class="search-icon" (click)="submitSearch()">search</mat-icon>
                        </mat-form-field>
                    </div>
                </form>

                <button class="header-button add" mat-button [matMenuTriggerFor]="belowMenu">
                    Add New <mat-icon>keyboard_arrow_down</mat-icon>
                    <mat-menu #belowMenu="matMenu" yPosition="below">
                        <button mat-menu-item (click)="openOfferForm()">Add Offer</button>
                        <button mat-menu-item (click)="openVendorForm()">Add Vendor</button>
                        <button mat-menu-item *ngIf="isAdmin()" (click)="openCategoryForm()">Add Category</button>
                        <button mat-menu-item (click)="openNewsForm()">Add News</button>
                        <button mat-menu-item *ngIf="isAdmin()" (click)="openUserForm()">Add User</button>
                        <button mat-menu-item *ngIf="isAdmin()" (click)="openLocationForm()">Add Location</button>
                        <!--                <button mat-menu-item *ngIf="isAdmin()" (click)="populateLocations()">Populate Locations</button>-->
                        <button mat-menu-item *ngIf="isAdmin()" (click)="openVersionForm()">Add Version</button>

                    </mat-menu>
                </button>

                <button type="button" class="header-button logout" mat-button (click)="logout.emit()">Logout</button>
            </mat-toolbar>
            <hr />
            <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="page-content">
            <app-categories-table *ngIf="showCategoryTable" [categories]="categories" [searchTerm]="searchTerm"
                (viewCategory)="viewCategory($event)" (editCategory)="openCategoryForm($event)"
                (deleteCategory)="deleteCategory($event)">
            </app-categories-table>
            <app-category-form *ngIf="showCategoryForm" [existingCat]="existingCat" [isEdit]="isEdit"
                (createCategory)="createCategory($event)" (updateCategory)="updateCategory($event)"
                (startLoading)="isLoading = true" (stopLoading)="isLoading = false" (goBack)="openCategoryList()">
            </app-category-form>
            <app-vendors-table *ngIf="showVendorTable" [vendors]="vendors" [canLoad]="canLoad"
                (viewVendor)="viewVendor($event)" (editVendor)="openVendorForm($event)"
                (activateVendor)="activateVendor($event)" (deleteVendor)="deleteVendor($event)"
                (downloadCSV)="downloadVendors()" (loadMore)="fetchVendors()">
            </app-vendors-table>
            <app-vendor-form *ngIf="showVendorForm" [existingVendor]="existingVendor" [isEdit]="isEdit"
                (createVendor)="createVendor($event)" (updateVendor)="updateVendor($event)"
                (startLoading)="isLoading = true" (stopLoading)="isLoading = false" (goBack)="openVendorList()">
            </app-vendor-form>
            <app-offers-table *ngIf="showOfferTable" [offers]="offers" [canLoad]="canLoad"
                (viewOffer)="viewOffer($event)" (editOffer)="openOfferForm($event)"
                (activateOffer)="activateOffer($event)" (deleteOffer)="deleteOffer($event)"
                (downloadCSV)="downloadOffers()" (UploadCSV)="UpdateOffersFromCSV()" (loadMore)="fetchOffers()">
            </app-offers-table>
            <app-offer-form *ngIf="showOfferForm" [existingOffer]="existingOffer" [isEdit]="isEdit"
                [categories]="categories" (createOffer)="createOffer($event)" (updateOffer)="updateOffer($event)"
                (createVendor)="openVendorDialog()" (startLoading)="isLoading = true" (stopLoading)="isLoading = false"
                (goBack)="openOfferList()">
            </app-offer-form>
            <app-feedback-table *ngIf="showFeedbackTable" [feedbackList]="feedback" [canLoad]="canLoad"
                (reviewFeedback)="reviewFeedback($event)" (downloadCSV)="downloadFeedback()"
                (loadMore)="fetchFeedback()">
            </app-feedback-table>
            <app-news-table *ngIf="showNewsTable" [newsList]="news" [canLoad]="canLoad" (viewNews)="viewNews($event)"
                (editNews)="openNewsForm($event)" (activateNews)="activateNews($event)"
                (deleteNews)="deleteNews($event)" (loadMore)="fetchNews()">
            </app-news-table>
            <app-news-form *ngIf="showNewsForm" [existingNews]="existingNews" [isEdit]="isEdit"
                (createNews)="createNews($event)" (updateNews)="updateNews($event)" (goBack)="openNewsList()">
            </app-news-form>
            <app-user-form *ngIf="showUserForm" [existingNews]="existingUser" [isEdit]="isEdit"
                (createUser)="createUser($event)" (updateUser)="updateUser($event)" (goBack)="openOfferList()">
            </app-user-form>
            <app-users-table *ngIf="showUsersTable" [usersList]="users" (deleteUser)="deleteUser($event)"
                (viewUsers)="viewUsers($event)">
            </app-users-table>

            <app-location-table *ngIf="showLocationTable" [Locationlist]="locations" [canLoad]="canLoad"
                (viewLocation)="viewLocation($event)" (loadMore)="fetchLocations()"
                (editLocation)="openLocationForm($event)" (deleteLocation)="deleteLocation($event)">
            </app-location-table>
            <app-location-form *ngIf="showLocationForm" (createLocation)="createLocation($event)"
                (goBack)="openLocationList()" (updateLocation)="updateLocation($event)"
                [existingLocation]="existingLocation" [isEdit]="isEdit">
            </app-location-form>

            <app-versions-table *ngIf="showVersionsTable" [versions]="versions" [searchTerm]="searchTerm" (editVersion)="openVersionForm($event)" (deleteVersion)="deleteVersion($event)">
            </app-versions-table>

            <app-version-form *ngIf="showVersionForm" [existingVersion]="existingVersion" [isEdit]="isEdit"
                (createVersion)="createVersion($event)" (updateVersion)="updateVersion($event)"
                (goBack)="openOfferList()">
            </app-version-form>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
