import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, } from '@angular/common/http';
import { News } from '../models/news.model';
import { HttpUtilsService } from './http-utils.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient, private utils: HttpUtilsService) { }

  getNewsData(searchTerm: string = '', skip: number = 0): Observable<News[]> {
    return this.http.get<News[]>('/api/cms-news?query=' + searchTerm + '&skip=' + skip, this.utils.httpOptions);
  }

  postNewsData(news: News): Observable<News> {
    return this.http.post<News>('/api/create-news', news, this.utils.httpOptions);
  }

  updateNews(news: News) {
    return this.http.post<News>('/api/update-news/' + news._id, news, this.utils.httpOptions);
  }

  deleteNews(news: News): Observable<News> {
    return this.http.post<News>('/api/delete-news/' + news._id, {}, this.utils.httpOptions);
  }
}
