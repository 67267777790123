<mat-dialog-content>
    <ng-container *ngIf="offer">
        <app-view-offer [offer]="offer"></app-view-offer>
    </ng-container>
    <ng-container *ngIf="vendor">
        <app-view-vendor [vendor]="vendor"></app-view-vendor>
    </ng-container>
    <ng-container *ngIf="category">
        <app-view-category [category]="category"></app-view-category>
    </ng-container>
    <ng-container *ngIf="news">
        <app-view-news [news]="news"></app-view-news>
    </ng-container>
    <ng-container *ngIf="user">
        <app-view-user [user]="user"></app-view-user>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
</mat-dialog-actions>