import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService } from './http-utils.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient, private utils: HttpUtilsService) { }

  getUsersData(): Observable<User[]> {
    return this.http.get<User[]>('/api/users', this.utils.httpOptions);
  }

  createUser(user: User): Observable<User> {
    return this.http.post<User>('/api/create-user', user, this.utils.httpOptions);
  }

  updateUser(user: User): Observable<User> {
    return this.http.post<User>('/api/update-user/' + user._id, user, this.utils.httpOptions);
  }

  deleteUser(user: User): Observable<User> {
    return this.http.post<User>('/api/delete-user/' + user._id, {}, this.utils.httpOptions);
  }
}