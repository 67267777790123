import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit {
  @Input() usersList: User[] = [];
  @Input() searchTerm = '';
  @Output() viewUsers =new EventEmitter<User>();
  @Output() deleteUser = new EventEmitter<User>();

  displayedColumns: string[] = ['Username', 'Email', 'Phone', 'Role', 'Actions'];
 
  constructor(private matDialog: MatDialog) { }

  ngOnInit(): void {
  }

  viewUserData(user: User) {
    this.viewUsers.emit(user);
  }
  
  deleteUserClicked(user: User) {
    const dialogConfig: MatDialogConfig = {
      data: {
        message: "Are you sure you want to delete?"
      }
    };
    this.matDialog.open(ConfirmDialogComponent, dialogConfig).afterClosed().subscribe(
      result => {
        if (result) {
          this.deleteUser.emit(user);
        } 
      }
    );
  }
}