import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilsService } from './http-utils.service';
import { Location } from '../models/offer.model';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private http: HttpClient, private utils: HttpUtilsService) { }

  getLocationsData(searchTerm: string = ''): Observable<Location[]> {
    return this.http.get<Location[]>('/api/locations?query=' + searchTerm, this.utils.httpOptions);
  }

  getAllLocations( searchTerm: string = '',skip: number = 0): Observable<Location[]> {
    return this.http.get<Location[]>('/api/locations?query=' + searchTerm +'&skip=' + skip, this.utils.httpOptions);
  }

  createLocation(location: Location): Observable<Location> {
    return this.http.post<Location>('/api/create-location', location, this.utils.httpOptions);
  }

  updateLocation(location: Location): Observable<Location> {
    return this.http.post<Location>('/api/update-location/' + location._id,location, this.utils.httpOptions);
  }
  deleteLocation(location: Location): Observable<Location> {
    return this.http.post<Location>('/api/delete-location/' + location._id,location, this.utils.httpOptions);
  }


  populateLocations(): Observable<{result: string}> {
    return this.http.post<{result: string}>('/api/populate-locations', {}, this.utils.httpOptions);
  }
}
