import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Feedback } from 'src/app/models/feedback.model';

@Component({
  selector: 'app-feedback-table',
  templateUrl: './feedback-table.component.html',
  styleUrls: ['./feedback-table.component.scss']
})
export class FeedbackTableComponent implements OnInit {
  @Input() feedbackList: Feedback[] = [];
  @Input() canLoad = false;
  @Input() isAdmin = false;
  @Output() reviewFeedback = new EventEmitter<Feedback>();
  @Output() downloadCSV = new EventEmitter<void>();
  @Output() loadMore = new EventEmitter<void>();

  displayedColumns: string[] = ['Type', 'Offer Title','Category','Vendor', 'Sender', 'Phone', 'Email', 'Text', 'Reviewed','Offer ID', "Is Valuable", "Is Used", "Rating"];

  constructor() { }

  ngOnInit(): void {
  }

  toggleReview(feedback: Feedback) {
    this.reviewFeedback.emit(feedback);
  }

  getFeedbackType(feedback: Feedback) {
    if (feedback.offerId) {
      return "Specific Offer"
    } else {
      return feedback.type;
    }
  }

  getYesNo(value: boolean) {
    if (value == null) {
      return '';
    }
    return value ? "Yes" : "No";
  }

}
