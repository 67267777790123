<!-- <div class="clickable-text" (click)="downloadCSV.emit()">Download CSV file</div> -->

<button type="button" (click)="downloadCSV.emit()" class="header-button download">Download CSV </button>



<table mat-table [dataSource]="vendors" class="data-table">

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="Logo">
        <th mat-header-cell *matHeaderCellDef>Logo</th>
        <td mat-cell *matCellDef="let vendor">
            <img class="row-img" [src]="'/api' + vendor.logo">
        </td>
    </ng-container>

    <ng-container matColumnDef="Vendor">
        <th mat-header-cell *matHeaderCellDef>Vendor Name</th>
        <td mat-cell *matCellDef="let vendor">{{vendor.name}}</td>
    </ng-container>

    <ng-container matColumnDef="Vendor_ar">
        <th mat-header-cell *matHeaderCellDef>Arabic Name</th>
        <td mat-cell *matCellDef="let vendor">{{vendor.name_ar}}</td>
    </ng-container>
    <ng-container matColumnDef="Vendor Description">
        <th mat-header-cell *matHeaderCellDef>Vendor Description</th>
        <td mat-cell *matCellDef="let vendor">{{vendor.description}}</td>
    </ng-container>
    <ng-container matColumnDef="Vendor Description Arabic">
        <th mat-header-cell *matHeaderCellDef>Vendor Description Arabic</th>
        <td mat-cell *matCellDef="let vendor">{{vendor.description_ar}}</td>
    </ng-container>

    <ng-container matColumnDef="Telephone">
        <th mat-header-cell *matHeaderCellDef>Telephone</th>
        <td mat-cell *matCellDef="let vendor">{{vendor.telephone}}</td>
    </ng-container>

    <ng-container matColumnDef="Mobile">
        <th mat-header-cell *matHeaderCellDef>Mobile</th>
        <td mat-cell *matCellDef="let vendor">{{vendor.mobile}}</td>
    </ng-container>

    <ng-container matColumnDef="Website">
        <th mat-header-cell *matHeaderCellDef>Website</th>
        <td mat-cell *matCellDef="let vendor">{{vendor.website}}</td>
    </ng-container>

    <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let vendor" class="toggle-status">
            <mat-slide-toggle [checked]="vendor.isActive" (click)=activateStatus(vendor)></mat-slide-toggle>
        </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let vendor">

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)=viewVendorData(vendor)>
                    <mat-icon>assignment</mat-icon>
                    <span>View</span>
                </button>
                <button mat-menu-item (click)=editVendorClicked(vendor)>
                    <mat-icon>mode_edit</mat-icon>
                    <span>Edit</span>
                </button>
                <button mat-menu-item (click)=deleteVendorClicked(vendor)>
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                </button>
            </mat-menu>

        </td>
    </ng-container>
</table>

<button mat-raised-button *ngIf="canLoad" class="big-button" (click)="loadMore.emit()">Load More</button>