import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  @Input() phone = '';
  @Output() submitOtp = new EventEmitter<string>();
  config: any;
  length = 6;


  constructor() { }

  ngOnInit(): void {
    this.config = {
      length: this.length,
      disableAutoFocus: true,
      isPasswordInput: false,
      allowNumbersOnly: true,
    }
  }

  onOtpChange(event: string) {
    if (event.length == this.length) {
      this.submitOtp.emit(event);
    }
  }

  getLastDigits(phone: string) {
    return phone.slice(-3);
  }

}
