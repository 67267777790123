import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { News } from 'src/app/models/news.model';
import { UploadImageComponent } from '../upload-image/upload-image.component';


@Component({
  selector: 'app-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.scss']
})
export class NewsFormComponent implements OnInit {
  
  @Input() isEdit = false;
  @Input() existingNews!: News;
  @Output() createNews = new EventEmitter<News>();
  @Output() updateNews = new EventEmitter<News>();
  @Output() goBack = new EventEmitter<void>();

  @ViewChild('uploadImage')
  private uploadImage!: UploadImageComponent;

  newsForm!: FormGroup;
  imageUrl = '';


  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    if (this.isEdit) {
      this.newsForm = this.formBuilder.group({
        title: new FormControl(this.existingNews.title, Validators.required),
        title_ar: new FormControl(this.existingNews.title_ar, Validators.required),
        description: new FormControl(this.existingNews.description, Validators.required),
        description_ar: new FormControl(this.existingNews.description_ar, Validators.required),
      });
      this.imageUrl = this.existingNews.image ? this.existingNews.image : '';
    } else {
      this.newsForm = this.formBuilder.group({
        title: new FormControl('', Validators.required),
        title_ar: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        description_ar: new FormControl('', Validators.required),
      });
      this.imageUrl = '';
    }
  }
  
  async submitForm() {

    const image = await this.uploadImage.triggerPost().toPromise();
    if (image) {
      this.imageUrl = image;
    }

    const newNews: News = {
      title: this.newsForm.get("title")?.value,
      title_ar: this.newsForm.get("title_ar")?.value,
      description: this.newsForm.get("description")?.value,
      description_ar: this.newsForm.get("description_ar")?.value,
      image: this.imageUrl,
      isActive: false,
    }

    if (this.isEdit) {
      newNews._id = this.existingNews._id;
      this.updateNews.emit(newNews);
    } else {
      this.createNews.emit(newNews);
    }
  }
     //removing image from news
     removeImage(){
      this.imageUrl= '';
    }
}