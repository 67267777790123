<div class="form-page-container">

    <button mat-mini-fab class="back-button" (click)="goBack.emit()">
    <mat-icon>arrow_back</mat-icon>
  </button>

    <form [formGroup]="newsForm" (ngSubmit)="submitForm()" class="form-container">

        <div class="form-title">{{isEdit ? 'Update' : 'Add'}} News</div>

        <mat-label class="field-label">Title</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="title" />
        </mat-form-field>


        <mat-label class="field-label">Arabic Title</mat-label>
        <mat-form-field appearance="outline">
            <input dir= rtl matInput type="text" formControlName="title_ar" />
        </mat-form-field>

        <mat-label class="field-label">Description</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="description" />
        </mat-form-field>


        <mat-label class="field-label">Arabic Description</mat-label>
        <mat-form-field appearance="outline">
            <input dir= rtl matInput type="text" formControlName="description_ar" />
        </mat-form-field>

        <mat-label class="field-label">Image</mat-label>

        <div class="image-container">
            <img *ngIf="imageUrl" class="img" [src]="'/api' + imageUrl">
            <mat-icon class="delete-icon" *ngIf="imageUrl" (click)="removeImage()">highlight_off</mat-icon>
        </div>
        <app-upload-image #uploadImage></app-upload-image><br>

        <button mat-raised-button type="submit" class="big-button" [disabled]="!newsForm.valid">{{isEdit ? 'Update' :
      'Add'}} News</button>
    </form>
</div>