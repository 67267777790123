<!-- <div class="clickable-text" (click)="downloadCSV.emit()">Download CSV file</div> -->

<button type="button" (click)="downloadCSV.emit()" class="header-button download">Download CSV </button>

<button *ngIf="showbtnUpload" type="button" (click)="fileImportInput.click()" class="header-button download">
    <span>{{uploadBtnTitle}}</span>
    <input type="file" #fileImportInput  style="display:none;" name="File Upload" id="csvFileUpload" (change)="fileChangeListener($event)" accept=".csv" />

  </button>

<button type="button" style="margin-left: 15px;" *ngIf="showbtnUpdate" class="header-button update" (click)="fetchOffers()">Update</button>

<table mat-table [dataSource]="offers" class="data-table">

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="Image">
        <th mat-header-cell *matHeaderCellDef>Image</th>
        <td mat-cell *matCellDef="let offer">
            <img *ngIf="offer.image" [src]="'/api' + offer.image" class="row-img">
        </td>
    </ng-container>

    <ng-container matColumnDef="Title">
        <th mat-header-cell *matHeaderCellDef>Offer Title</th>
        <td mat-cell *matCellDef="let offer">{{offer.title}}</td>
    </ng-container>

    <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let offer">{{offer.description}}</td>
    </ng-container>

    <ng-container matColumnDef="Category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let offer">
            <ng-container *ngIf="offer.category?.name">{{offer.category.name}}</ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="Vendor">
        <th mat-header-cell *matHeaderCellDef>Vendor</th>
        <td mat-cell *matCellDef="let offer">{{offer.vendor.name}}</td>
    </ng-container>

    <ng-container matColumnDef="Expiry Date">
        <th mat-header-cell *matHeaderCellDef>Expiry Date</th>
        <td mat-cell *matCellDef="let offer">{{offer.expiryDate | date:"shortDate"}}</td>
    </ng-container>

    <ng-container matColumnDef="Status" class="toggle-status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let offer" class="toggle-status">
            <mat-slide-toggle [checked]="offer.isActive" (click)=activateStatus(offer)></mat-slide-toggle>
        </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let offer">

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)=viewOfferData(offer)>
                    <mat-icon>assignment</mat-icon>
                    <span>View</span>
                </button>
                <button mat-menu-item (click)=editOfferClicked(offer)>
                    <mat-icon>mode_edit</mat-icon>
                    <span>Edit</span>
                </button>
                <button mat-menu-item (click)=deleteOfferClicked(offer)>
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                </button>

            </mat-menu>

        </td>
    </ng-container>
</table>

<button mat-raised-button *ngIf="canLoad" class="big-button" (click)="loadMore.emit()">Load More</button>