<div class="login-page">
  <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()" method="post" autocomplete="off">

    <div class="header">CMS Login</div>

    <mat-form-field class='field' appearance="fill">
      <mat-label>Username</mat-label>
      <input matInput formControlName="username">
    </mat-form-field>

    <mat-form-field class='field' appearance="fill">
      <mat-label>Password</mat-label>
      <input matInput type="password" formControlName="password">
    </mat-form-field>

    <button mat-raised-button class="big-button" type="submit" [disabled]="!loginForm.valid">Submit</button>
  </form>

</div>