import { Component, OnInit, EventEmitter, Input, Output} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { News } from 'src/app/models/news.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-news-table',
  templateUrl: './news-table.component.html',
  styleUrls: ['./news-table.component.scss']
})
export class NewsTableComponent implements OnInit {
  @Input() canLoad = false;
  @Input() newsList: News[] = [];
  @Output() viewNews =new EventEmitter<News>();
  @Output() editNews = new EventEmitter<News>();
  @Output() deleteNews = new EventEmitter<News>();
  @Output() activateNews = new EventEmitter<News>();
  @Output() loadMore = new EventEmitter<void>();

  displayedColumns: string[] = ['Title', 'Arabic Title','Description','Arabic Description' ,'Image', 'Status', 'Actions'];

  constructor(private matDialog: MatDialog) { }

  ngOnInit(): void {
  }
  
  editNewsClicked(news: News) {
    this.editNews.emit(news);
  }

  viewNewsData(news: News) {
    this.viewNews.emit(news);
  }
  activateStatus(news: News) {
    this.activateNews.emit(news);
  }
  
  deleteNewsClicked(news: News) {
    const dialogConfig: MatDialogConfig = {
      data: {
        message: "Are you sure you want to delete?"
      }
    };
    this.matDialog.open(ConfirmDialogComponent, dialogConfig).afterClosed().subscribe(
      result => {
        if (result) {
          this.deleteNews.emit(news);
        } 
      }
    );
  }



}
