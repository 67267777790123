<table mat-table [dataSource]="getFilteredList()" class="data-table">

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="Channel">
        <th mat-header-cell *matHeaderCellDef>Channel</th>
        <td mat-cell *matCellDef="let version">{{version.channel}}</td>
    </ng-container>

    <ng-container matColumnDef="Version Number">
        <th mat-header-cell *matHeaderCellDef>Version Number</th>
        <td mat-cell *matCellDef="let version">{{version.version}}</td>
    </ng-container>

    <ng-container matColumnDef="Force Update">
        <th mat-header-cell *matHeaderCellDef>Force Update</th>
        <td mat-cell *matCellDef="let version">{{version.forceUpdate ? "Yes" : "No"}}</td>
    </ng-container>

    <ng-container matColumnDef="Enable Localization">
        <th mat-header-cell *matHeaderCellDef>Enable Localization</th>
        <td mat-cell *matCellDef="let version">{{version.islocalized ? "Yes" : "No"}}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let version">

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
          </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)=editVersionClicked(version)>
                  <mat-icon>mode_edit</mat-icon>
                  <span>Edit</span>
              </button>
                <button mat-menu-item (click)="deleteVersionClicked(version)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
              </button>
            </mat-menu>
        </td>
    </ng-container>
</table>