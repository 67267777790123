<!-- <div class="clickable-text" (click)="downloadCSV.emit()">Download CSV file</div> -->


<button type="button" (click)="downloadCSV.emit()" class="header-button download">Download CSV </button>

<table mat-table [dataSource]="feedbackList" class="data-table">

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef>Feedback Type</th>
        <td mat-cell *matCellDef="let feedback">{{getFeedbackType(feedback)}}</td>
    </ng-container>

    <ng-container matColumnDef="Offer Title">
        <th mat-header-cell *matHeaderCellDef>Offer Title</th>
        <td mat-cell *matCellDef="let feedback">{{feedback.offerTitle}}</td>
    </ng-container>

    <ng-container matColumnDef="Category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let feedback">{{feedback.category}}</td>
    </ng-container>

    <ng-container matColumnDef="Vendor">
        <th mat-header-cell *matHeaderCellDef>Vendor</th>
        <td mat-cell *matCellDef="let feedback">{{feedback.vendorName}}</td>
    </ng-container>


    <ng-container matColumnDef="Sender">
        <th mat-header-cell *matHeaderCellDef>Sender</th>
        <td mat-cell *matCellDef="let feedback">{{feedback.sender}}</td>
    </ng-container>

    <ng-container matColumnDef="Phone">
        <th mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let feedback">{{feedback.phone}}</td>
    </ng-container>

    <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let feedback">{{feedback.email}}</td>
    </ng-container>

    <ng-container matColumnDef="Text">
        <th mat-header-cell *matHeaderCellDef>Feedback Text</th>
        <td mat-cell *matCellDef="let feedback">{{feedback.text}}</td>
    </ng-container>

    <ng-container matColumnDef="Reviewed" class="toggle-status">
        <th mat-header-cell *matHeaderCellDef>Reviewed</th>
        <td mat-cell *matCellDef="let feedback" class="toggle-status">
            <mat-slide-toggle [checked]="feedback.reviewed" (click)=toggleReview(feedback)></mat-slide-toggle>
        </td>
    </ng-container>

    <ng-container matColumnDef="Offer ID">
        <th mat-header-cell *matHeaderCellDef>Offer ID</th>
        <td mat-cell *matCellDef="let feedback">{{feedback.offerId}}</td>
    </ng-container>

    <ng-container matColumnDef="Is Valuable">
        <th mat-header-cell *matHeaderCellDef>Is Valuable</th>
        <td mat-cell *matCellDef="let feedback">{{getYesNo(feedback.isValuable)}}</td>
    </ng-container>

    <ng-container matColumnDef="Is Used">
        <th mat-header-cell *matHeaderCellDef>Is Used</th>
        <td mat-cell *matCellDef="let feedback">{{getYesNo(feedback.isUsed)}}</td>
    </ng-container>

    <ng-container matColumnDef="Rating">
        <th mat-header-cell *matHeaderCellDef>Rating</th>
        <td mat-cell *matCellDef="let feedback">{{feedback.rating == 0 ? '' : feedback.rating}}</td>
    </ng-container>


</table>

<button mat-raised-button *ngIf="canLoad" class="big-button" (click)="loadMore.emit()">Load More</button>