import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, } from '@angular/common/http';
import { Category } from '../models/category.model';
import { HttpUtilsService } from './http-utils.service';

@Injectable({
  providedIn: 'root' // service is registered in root module (app.module.ts)
})
export class CategoriesService {

  constructor(private http: HttpClient, private utils: HttpUtilsService) { }

  getCategoriesData(searchTerm: string, skip: number): Observable<Category[]> {
    return this.http.get<Category[]>('/api/categories?query=' + searchTerm + '&skip=' + skip, this.utils.httpOptions); // fetching data from api
  }

  createCategory(category: Category) {
    return this.http.post<Category>('/api/create-category', category, this.utils.httpOptions);

  }

  updateCategory(category: Category) {
    return this.http.post<Category>('/api/update-category/' + category._id, category, this.utils.httpOptions);
  }

  deleteCategory(category: Category): Observable<Category> {
    return this.http.post<Category>('/api/delete-category/' + category._id, {}, this.utils.httpOptions);
  }

}
