import { Component, Input, OnInit } from '@angular/core';
import { Offer } from 'src/app/models/offer.model';

@Component({
  selector: 'app-view-offer',
  templateUrl: './view-offer.component.html',
  styleUrls: ['./view-offer.component.scss']
})
export class ViewOfferComponent implements OnInit {
  @Input() offer!: Offer;
  constructor() { }

  ngOnInit(): void {
  }

}
