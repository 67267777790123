<table mat-table [dataSource]="newsList" class="data-table">

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="Title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let news">{{news.title}}</td>
    </ng-container>

    <ng-container matColumnDef="Arabic Title">
        <th mat-header-cell *matHeaderCellDef>Title_ar</th>
        <td mat-cell *matCellDef="let news">{{news.title_ar}}</td>
    </ng-container>

    <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let news">{{news.description}}</td>
    </ng-container>

    <ng-container matColumnDef="Arabic Description">
        <th mat-header-cell *matHeaderCellDef>Description_ar</th>
        <td mat-cell *matCellDef="let news">{{news.description_ar}}</td>
    </ng-container>

    <ng-container matColumnDef="Image">
        <th mat-header-cell *matHeaderCellDef>Image</th>
        <td mat-cell *matCellDef="let news">
            <img [src]="'/api/' + news.image" class="row-img">
        </td>
    </ng-container>

    <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let news" class="toggle-status">
            <mat-slide-toggle [checked]="news.isActive" (click)=activateStatus(news)></mat-slide-toggle>
        </td>
    </ng-container>


    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let news">

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)=viewNewsData(news)>
                    <mat-icon>assignment</mat-icon>
                    <span>View</span>
                </button>
                <button mat-menu-item (click)=editNewsClicked(news)>
                    <mat-icon>mode_edit</mat-icon>
                    <span>Edit</span>
                </button>
                <button mat-menu-item (click)="deleteNewsClicked(news)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                </button>
            </mat-menu>
        </td>
    </ng-container>
</table>

<button mat-raised-button *ngIf="canLoad" class="big-button" (click)="loadMore.emit()">Load More</button>