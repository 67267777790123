<div class="form-page-container">

    <button mat-mini-fab class="back-button" (click)="goBack.emit()">
    <mat-icon>arrow_back</mat-icon>
  </button>

    <form [formGroup]="versionForm" (ngSubmit)="submitForm()" class="form-container">

        <div class="form-title">{{isEdit ? 'Update' : 'Add'}} Version</div>

        <mat-label class="field-label">Channel</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="channel" />
        </mat-form-field>

        <mat-label class="field-label">Version</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="version" />
        </mat-form-field>

        <mat-checkbox formControlName="forceUpdate">Force Update</mat-checkbox>

        <mat-checkbox formControlName="islocalized">Enable Localization</mat-checkbox>

        <button mat-raised-button type="submit" class="big-button" [disabled]="!versionForm.valid">{{isEdit ? 'Update' : 'Add'}} Version</button>
    </form>
</div>