import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Offer } from '../models/offer.model';
import { updateOfferReq } from '../models/updateOfferReq.model';
import { HttpUtilsService } from './http-utils.service';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  constructor(private http: HttpClient, private utils: HttpUtilsService) { }

  getOffersData(searchTerm: string, skip: number): Observable<Offer[]> {
    return this.http.post<Offer[]>('/api/cms-offers', {query: searchTerm, skip: skip}, this.utils.httpOptions);
  }
  getOffersCsv(): Observable<{csv: string}> {
    return this.http.get<{csv: string}>('/api/offers-csv', this.utils.httpOptions);
  }

  createOffer(offer: Offer) {
    return this.http.post<Offer>('/api/create-offer', offer, this.utils.httpOptions);
  }

  updateOffer(offer: Offer) {
    return this.http.post<Offer>('/api/update-offer/' + offer._id, offer, this.utils.httpOptions);
  }

  deleteOffer(offer: Offer): Observable<Offer> {
    return this.http.post<Offer>('/api/delete-offer/' + offer._id, {}, this.utils.httpOptions);
  }


  updateOfferFromCSV(offers: updateOfferReq) {
    return this.http.post<{data: string}>('/api/update-offerList', offers, this.utils.httpOptions);
  }
}
