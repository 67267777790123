import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Version } from '../../models/version.model';

@Component({
  selector: 'app-version-form',
  templateUrl: './version-form.component.html',
  styleUrls: ['./version-form.component.scss']
})
export class VersionFormComponent implements OnInit {
  @Input() isEdit = false;
  @Input() existingVersion!: Version;
  @Output() createVersion = new EventEmitter<Version>();
  @Output() updateVersion = new EventEmitter<Version>();
  @Output() goBack = new EventEmitter<void>();

  versionForm!: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.versionForm = this.formBuilder.group({
      channel: this.isEdit ? new FormControl(this.existingVersion.channel) : new FormControl(),
      version: this.isEdit ? new FormControl(this.existingVersion.version) : new FormControl(),
      forceUpdate: this.isEdit ? new FormControl(this.existingVersion.forceUpdate) : new FormControl(),
      islocalized: this.isEdit ? new FormControl(this.existingVersion.islocalized) : new FormControl(),

      
    });
  }

  submitForm(): void {
    const newVersion: Version = {
      channel: this.versionForm.get('channel')?.value,
      version: this.versionForm.get('version')?.value,
      forceUpdate: this.versionForm.get('forceUpdate')?.value,
      islocalized: this.versionForm.get('islocalized')?.value,
    };

    if (this.isEdit) {
      newVersion._id = this.existingVersion._id;
      this.updateVersion.emit(newVersion);
    } else {
      this.createVersion.emit(newVersion);
    }
  }

}
