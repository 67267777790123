<table mat-table [dataSource]="usersList" class="data-table">

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="Username">
        <th mat-header-cell *matHeaderCellDef>Username</th>
        <td mat-cell *matCellDef="let users">{{users.username}}</td>
    </ng-container>
    <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let users">{{users.email}}</td>
    </ng-container>
    <ng-container matColumnDef="Phone">
        <th mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let users">{{users.phone}}</td>
    </ng-container>
    <ng-container matColumnDef="Role">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td mat-cell *matCellDef="let users">{{users.role}}</td>
    </ng-container>
    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let users">

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)=viewUserData(users)>
                    <mat-icon>assignment</mat-icon>
                    <span>View</span>
                </button>
                <button mat-menu-item (click)="deleteUserClicked(users)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                </button>
            </mat-menu>
        </td>
    </ng-container>
</table>