 <div class="row">
    <div class="view-title">Offer Details </div>
</div>

 <div class="view-dialog">
    <div class="dialog-container">
        <div class="row">
            <div class="view-label">ID: </div>
            <div class="view-data">{{offer._id}}</div>
        </div>
    
        <div class="row">
            <div class="view-label">Title: </div>
            <div class="view-data">{{offer.title}}</div>
        </div>
    
        <div class="row">
            <div class="view-label">Description: </div>
            <div class="view-data">{{offer.description}}</div>
        </div>
    
        <div class="row">
            <div class="view-label">Expiry Date: </div>
            <div class="view-data">{{offer.expiryDate}}</div>
        </div>
    
        <div class="row">
            <div class="view-label">Highlights: </div>
            <div class="view-data">{{offer.highlights}}</div>
        </div>
    
        <div class="row">
            <div class="view-label">Discount Code: </div>
            <div class="view-data">{{offer.discountCode}}</div>
        </div>
    
        <div class="row">
            <div class="view-label">howToAvail: </div>
            <div class="view-data">{{offer.howToAvail}}</div>
        </div>
    
        <div class="row">
            <div class="view-label">isPartnerHotel: </div>
            <div class="view-data">{{offer.isPartnerHotel}}</div>
        </div>
    
        <div class="row">
            <div class="view-label">isOccasional: </div>
            <div class="view-data">{{offer.isOccasional}}</div>
        </div>
    
        <div class="row">
            <div class="view-label">isFeatured: </div>
            <div class="view-data">{{offer.isFeatured}}</div>
        </div>
        <div class="row">
            <div class="view-label">Offer Details: </div>
            <div class="view-data">{{offer.offerDetails}}</div>
        </div>
        <div class="row">
            <div class="view-label">featured Image: </div>
            <img class=" view-data view-img" [src]="'/api' + offer.featuredImage">
        </div>
    
        <div class="row">
            <div class="view-label">Image: </div>
            <img class="view-data view-img " [src]="'/api' + offer.image">
        </div>
    
        <div class="row">
            <div class="view-label">Search Keywords: </div>
            <div class="view-data" *ngFor="let searchkey of offer.searchKeywords">
                ({{searchkey}} )
            </div>
        </div>
    
        <div class="row">
            <div class="view-label"> Offer Status: </div>
            <div class="view-data">{{offer.isActive}}</div>
        </div>
    
        <div class="row">
            <div class="view-label">Locations: </div>
            <div class="view-data" *ngFor="let location of offer.locations">
                ({{location.country}}, {{location.city}}, {{location.longitude}}, {{location.latitude}} )
            </div>
        </div>
    
        <div class="row">
            <div class="view-label">Hotel Amenitites: </div>
            <div class="view-data" *ngFor="let hotel of offer.hotelAmenitites">
                ({{hotel.name}} )
            </div>
        </div>
    
        <div class="row">
            <div class="view-label"> Tax Value: </div>
            <div class="view-data">{{offer.taxValue}}</div>
        </div>
    
        <div class="row">
            <div class="view-label"> Currency: </div>
            <div class="view-data">{{offer.currency}}</div>
        </div>
    
        <div class="row">
            <div class="view-label">Rooms: </div>
            <div class="view-data" *ngFor="let room of offer.rooms">
                ({{room.name}},{{room.additionalInformation}})
    
                 <br><br>
                <div class="view-label">Rates: </div>
                <div class="view-data" *ngFor="let rate of room.rates">
                    ({{rate.season}}, {{rate.value}})
                </div>
            </div>
        </div>

    </div>
</div>


<app-view-vendor *ngIf="offer.vendor" [vendor]="offer.vendor"></app-view-vendor>

<app-view-category *ngIf="offer.category" [category]="offer.category"></app-view-category>