import { Component, Input, OnInit } from '@angular/core';
import { Vendor } from 'src/app/models/vendor.model';

@Component({
  selector: 'app-view-vendor',
  templateUrl: './view-vendor.component.html',
  styleUrls: ['./view-vendor.component.scss']
})
export class ViewVendorComponent implements OnInit {
  @Input() vendor!: Vendor;
  constructor() { }

  ngOnInit(): void {
  }

}
