import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Version } from '../models/version.model';
import { Observable } from 'rxjs';
import { HttpUtilsService } from './http-utils.service';

@Injectable({
  providedIn: 'root'
})
export class VersionsService {

  constructor(private http: HttpClient, private utils: HttpUtilsService) { }

  getVersionsData(): Observable<Version[]> {
    return this.http.get<Version[]>('/api/versions', this.utils.httpOptions);
  }

  postVersion(version: Version): Observable<Version> {
    return this.http.post<Version>('/api/submit-version', version, this.utils.httpOptions);
  }

  updateVersions(version: Version) {
    return this.http.post<Version>('/api/update-version/' + version._id, version, this.utils.httpOptions);
  }

  deleteVersions(version: Version): Observable<Version> {
    return this.http.post<Version>('/api/delete-version/' + version._id, {}, this.utils.httpOptions);
  }
}
