import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from 'src/app/models/offer.model';


@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss']
})
export class LocationFormComponent implements OnInit {

  @Input() isEdit = false;
  @Input() existingLocation!: Location;
  @Output() createLocation = new EventEmitter<Location>();
  @Output() updateLocation = new EventEmitter<Location>();
  @Output() goBack = new EventEmitter<void>();

  locationForm!: FormGroup;

  constructor(private formBuilder: FormBuilder) { }



  ngOnInit(): void {

    if (this.isEdit) {
      this.locationForm = this.formBuilder.group({
        city: new FormControl(this.existingLocation.city, Validators.required),
        city_ar: new FormControl(this.existingLocation.city_ar, Validators.required),
        country: new FormControl(this.existingLocation.country, Validators.required),
        country_ar: new FormControl(this.existingLocation.country_ar, Validators.required),
        longitude: new FormControl(this.existingLocation.longitude? this.existingLocation.longitude: ''),
        latitude: new FormControl(this.existingLocation.latitude? this.existingLocation.latitude: ''),
      });
     
    } 
    else
    {
      this.locationForm = this.formBuilder.group({
        city: new FormControl('', Validators.required),
        city_ar: new FormControl('', Validators.required),
        country: new FormControl('', Validators.required),
        country_ar: new FormControl('', Validators.required),
        longitude: new FormControl(''),
        latitude: new FormControl('')
      });

    }
  }

 

  async submitForm() {
    const newLocation: Location = {
      city: this.locationForm.get("city")?.value,
      city_ar: this.locationForm.get("city_ar")?.value,
      country: this.locationForm.get("country")?.value,
      country_ar: this.locationForm.get("country_ar")?.value,
      longitude: this.locationForm.get("longitude")?.value,
      latitude: this.locationForm.get("latitude")?.value
    }

    if (this.isEdit) {

      newLocation._id = this.existingLocation._id;
      this.updateLocation.emit(newLocation);
    }
    else
    {
      this.createLocation.emit(newLocation);
    }


  }

}
