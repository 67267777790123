<div class="form-page-container">

  <button mat-mini-fab class="back-button" (click)="goBack.emit()">
    <mat-icon>arrow_back</mat-icon>
  </button>

  <form class="form-container" [formGroup]="userForm" (ngSubmit)="submitForm()" autocomplete="off" method="post">

    <div class="form-title">{{isEdit ? 'Update' : 'Add'}} User</div>

    <mat-label class="field-label">Username</mat-label>
    <mat-form-field appearance="outline">
      <input matInput formControlName="username" required>
    </mat-form-field>

    <mat-label class="field-label">Password</mat-label>
    <mat-form-field appearance="outline">
      <input matInput type="password" formControlName="password" required>
    </mat-form-field>

    <mat-label class="field-label">Phone</mat-label>
    <mat-form-field appearance="outline">
      <input matInput formControlName="phone" required>
    </mat-form-field>

    <mat-label class="field-label">Email</mat-label>
    <mat-form-field appearance="outline">
      <input matInput formControlName="email" required>
    </mat-form-field>

    <mat-label class="field-label">Role</mat-label>
    <mat-form-field appearance="outline">
      <mat-select formControlName="role" required>
        <mat-option *ngFor="let role of roles" [value]="role">
          {{role}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button class="big-button" type="submit" [disabled]="!userForm.valid">
      {{isEdit ? 'Update' : 'Add'}} User
    </button>

  </form>
</div>