<table mat-table [dataSource]="Locationlist" class="data-table">

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="Country">
        <th mat-header-cell *matHeaderCellDef>Country</th>
        <td mat-cell *matCellDef="let Location">{{Location.country}}</td>
    </ng-container>
    <ng-container matColumnDef="City">
        <th mat-header-cell *matHeaderCellDef>City</th>
        <td mat-cell *matCellDef="let Location">{{Location.city}}</td>
    </ng-container>

    <ng-container matColumnDef="City Arabic">
        <th mat-header-cell *matHeaderCellDef>City Arabic</th>
        <td mat-cell *matCellDef="let Location">{{Location.city_ar}}</td>
    </ng-container>


    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let location">

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
          </button>

            <mat-menu #menu="matMenu">
                <!-- <button mat-menu-item (click)=viewNewsData(news)>
                  <mat-icon>assignment</mat-icon>
                  <span>View</span>
              </button> -->
                <button mat-menu-item (click)=editLocationClicked(location)>
                  <mat-icon>mode_edit</mat-icon>
                  <span>Edit</span>
              </button>
                <button mat-menu-item (click)="deleteNewsClicked(location)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
              </button>
            </mat-menu>
        </td>
    </ng-container>
</table>

<button mat-raised-button *ngIf="canLoad" class="big-button" (click)="loadMore.emit()">Load More</button>