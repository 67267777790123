  <div class="row">
    <div class="view-title">Vendor Details </div>
  </div>

<div class="view-dialog">
    <div class="dialog-container">
        <div class="row">
            <div class="view-label">ID: </div>
            <div class="view-data">{{vendor?._id}}</div>
        </div>
       
        <div class="row">
            <div class="view-label">Name: </div>
            <div class="view-data">{{vendor?.name}}</div>
        </div>
       
        <div class="row">
            <div class="view-label">Logo: </div>
            <img class="view-data view-img" [src]="'/api' + vendor?.logo">
        </div>
       
        <div class="row">
            <div class="view-label">Description: </div>
            <div class="view-data">{{vendor?.description}}</div>
        </div>
       
       
        <div class="row">
            <div class="view-label">Telephone: </div>
            <div class="view-data">{{vendor?.telephone}}</div>
        </div>
       
       
        <div class="row">
            <div class="view-label">Mobile: </div>
            <div class="view-data">{{vendor?.mobile}}</div>
        </div>
       
       
        <div class="row">
            <div class="view-label">Email: </div>
            <div class="view-data">{{vendor?.email}}</div>
        </div>
       
        <div class="row">
            <div class="view-label">Website: </div>
            <div class="view-data">{{vendor?.website}}</div>
        </div>
       
        <div class="row">
            <div class="view-label">SME Name: </div>
            <div class="view-data">{{vendor?.smeName}}</div>
        </div>
       
        <div class="row">
            <div class="view-label">SME Email: </div>
            <div class="view-data">{{vendor?.smeEmail}}</div>
        </div>
       
        <div class="row">
            <div class="view-label">SME Phone: </div>
            <div class="view-data">{{vendor?.smePhone}}</div>
        </div>
       
        <div class="row">
            <div class="view-label">Status: </div>
            <div class="view-data">{{vendor?.isActive}}</div>
        </div>
    
    </div>
</div>