import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Vendor } from 'src/app/models/vendor.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-vendors-table',
  templateUrl: './vendors-table.component.html',
  styleUrls: ['./vendors-table.component.scss']
})
export class VendorsTableComponent implements OnInit {
  @Input() vendors: Vendor[] = [];
  @Input() canLoad = true;
  @Output() viewVendor = new EventEmitter<Vendor>();
  @Output() editVendor = new EventEmitter<Vendor>();
  @Output() activateVendor = new EventEmitter<Vendor>();
  @Output() deleteVendor = new EventEmitter<Vendor>();
  @Output() loadMore = new EventEmitter<void>();
  @Output() downloadCSV = new EventEmitter<void>();
  displayedColumns: string[] = ['Logo', 'Vendor', 'Vendor_ar','Vendor Description','Vendor Description Arabic','Telephone', 'Mobile', 'Website', 'Status', 'Actions'];


  constructor(private matDialog: MatDialog) { } // dependency injection


  ngOnInit(): void {
  }

  viewVendorData(vendor: Vendor) {
    this.viewVendor.emit(vendor);
  }

  editVendorClicked(vendor: Vendor) {
    this.editVendor.emit(vendor);
  }

  activateStatus(vendor: Vendor) {
    this.activateVendor.emit(vendor);
  }

  deleteVendorClicked(vendor: Vendor) {
    const dialogConfig: MatDialogConfig = {
      data: {
        message: "Are you sure you want to delete?"
      }
    };
    this.matDialog.open(ConfirmDialogComponent, dialogConfig).afterClosed().subscribe(
      result => {
        if (result) {
          this.deleteVendor.emit(vendor);
        }
      }
    );
  }
}