<div class="row">
    <div class="view-title">User Details</div>
</div>

<div class="view-dialog">
    <div class="dialog-container">
        <div class="row">
            <div class="view-label">Username: </div>
            <div class="view-data">{{user?.username}}</div>
        </div>
        <div class="row">
            <div class="view-label"> Email: </div> 
            <div class="view-data">{{user?.email}}</div>
        </div>
        <div class="row">
            <div class="view-label"> Phone: </div> 
            <div class="view-data">{{user?.phone}}</div>
        </div>
        <div class="row">
            <div class="view-label"> Role: </div> 
            <div class="view-data">{{user?.role}}</div>
        </div>
    </div>
</div>