<div class="row">
    <div class="view-title">Category Details </div>
</div>

<div class="view-dialog">
    <div class="dialog-container">
        <div class="row">
            <div class="view-label">ID: </div>
            <div class="view-data">{{category?._id}}</div>
        </div>
        <div class="row">
            <div class="view-label"> Name: </div> 
            <div class="view-data">{{category?.name}}</div>
        </div>
        <div class="row">
            <div class="view-label"> Icon: </div> 
            <img class="view-data view-img" [src]="'/api' + category?.icon">
        </div>
        <div class="row">
            <div class="view-label"> Image: </div> 
            <img class="view-data view-img" [src]="'/api' + category?.image">
        </div>
    </div>
</div>  