import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Category } from 'src/app/models/category.model';
import { HotelAmenitites, Location, Offer, Rate, Room } from 'src/app/models/offer.model';
import { Vendor } from 'src/app/models/vendor.model';
import { Currency } from 'src/app/models/curreny.model';
import { UploadImageComponent } from '../upload-image/upload-image.component';
// import { currencies } from 'currencies.json';
import { Observable, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { VendorsService } from 'src/app/services/vendors.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { LocationsService } from 'src/app/services/locations.service';
import * as moment from 'moment';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-offer-form',
  templateUrl: './offer-form.component.html',
  styleUrls: ['./offer-form.component.scss']
})
export class OfferFormComponent implements OnInit {
  @Input() isEdit = false;
  @Input() existingOffer!: Offer;
  @Input() categories: Category[] = [];
  @Output() createOffer = new EventEmitter<Offer>();
  @Output() updateOffer = new EventEmitter<Offer>();
  @Output() createVendor = new EventEmitter<void>();
  @Output() startLoading = new EventEmitter<void>();
  @Output() stopLoading = new EventEmitter<void>();
  @Output() goBack = new EventEmitter<void>();
  
  @ViewChild('uploadImage')
  private uploadImage!: UploadImageComponent;
  @ViewChild('uploadFeaturedImage')
  private uploadFeaturedImage!: UploadImageComponent;

  @ViewChild('locationInput')
  locationInput!: ElementRef<HTMLInputElement>;

  offerForm!: FormGroup;
  imageUrl = '';
  featuredImageUrl = '';
  currenciesList: String[] = [];

  filteredVendors!: Observable<Vendor[]>;
  filteredLocations!: Observable<Location[]>;
  addedLocations: Location[] = [];
  currencies: Currency []= [];

  showHotelSection = false;
  hotelAmenititesList: HotelAmenitites[] = [
    { name: 'Breakfast', name_ar: 'فطور'},
    { name: 'Wifi',name_ar: 'واي فاي'  },
    { name: 'Restaurants',name_ar: 'مطاعم' },
    { name: 'Laundry', name_ar: 'غسيل ملابس' },
    { name: 'Spa' , name_ar: 'سباه' },
    { name: 'Others',name_ar: 'اخري' }
  ];

  constructor(private formBuilder: FormBuilder, private locationService : LocationsService, private vendorService : VendorsService,private httpClient: HttpClient) { }

    ngOnInit(): void {

   



     this.getCurrenciesList();

   
    if (this.isEdit) {
      if(this.existingOffer.enableMapSearch == null )
      {
        this.existingOffer.enableMapSearch = true;
      }
      this.offerForm = this.formBuilder.group({
        title: new FormControl(this.existingOffer.title, Validators.required),
        title_ar: new FormControl(this.existingOffer.title_ar, Validators.required),
        description: new FormControl(this.existingOffer.description, Validators.required),
        description_ar: new FormControl(this.existingOffer.description_ar, Validators.required),
        category: new FormControl(this.existingOffer.category, Validators.required), 
        vendor: new FormControl(this.existingOffer.vendor, Validators.required),
        isPartnerHotel: new FormControl(this.existingOffer.isPartnerHotel),
        highlights: new FormControl(this.existingOffer.highlights),
        highlights_ar: new FormControl(this.existingOffer.highlights_ar),
        howToAvail: new FormControl(this.existingOffer.howToAvail),
        howToAvail_ar: new FormControl(this.existingOffer.howToAvail_ar),
        isOccasional: new FormControl(this.existingOffer.isOccasional),
        expiryDate: new FormControl(this.existingOffer.expiryDate ? moment(this.existingOffer.expiryDate) : ''),
        isFeatured: new FormControl(this.existingOffer.isFeatured),
        enableMapSearch: new FormControl(this.existingOffer.enableMapSearch),
        hotelAmenitites: new FormControl(this.existingOffer.hotelAmenitites),
        taxValue: new FormControl(this.existingOffer.taxValue),
        taxValue_ar: new FormControl(this.existingOffer.taxValue_ar),
        currency: new FormControl(this.existingOffer.currency),
        rooms: new FormArray([]),
        locations: new FormControl(""), //this
        contacts: new FormArray([]),
        telephones: new FormArray([]),
        mobiles: new FormArray([]),
        emails: new FormArray([]),
        website: new FormControl(this.existingOffer.website),
        discountCode: new FormControl(this.existingOffer.discountCode)
      });
      this.imageUrl = this.existingOffer.image ? this.existingOffer.image : '';
      this.featuredImageUrl = this.existingOffer.featuredImage ? this.existingOffer.featuredImage : '';
      this.addedLocations = this.existingOffer.locations ? this.existingOffer.locations : [];
      this.showRelatedFields(this.existingOffer.category);

      // populate FormArray fields with data - or display one empty field
      if (this.existingOffer.contacts && this.existingOffer.contacts.length > 0) {
        for (var contact of this.existingOffer.contacts) {
          this.contacts.push(new FormControl(contact));
        }
      } else {
        this.contacts.push(new FormControl(""));
      }

    // populate FormArray fields with data - or display one empty field
     if (this.existingOffer.rooms && this.existingOffer.rooms.length > 0) {
      for (var room of this.existingOffer.rooms) {
        this.getRooms().push(this.exportRoom(room));
      }
     } else {
      this.getRooms().push(this.initRoom());
     }
      // populate FormArray fields with data - or display one empty field
      if (this.existingOffer.telephone && this.existingOffer.telephone.length > 0) {
        for (var tele of this.existingOffer.telephone) {
          this.telephones.push(new FormControl(tele));
        }
      } else {
        this.telephones.push(new FormControl(""));
      }
      // populate FormArray fields with data - or display one empty field
      if (this.existingOffer.mobile && this.existingOffer.mobile.length > 0) {
        for (var mob of this.existingOffer.mobile) {
          this.mobiles.push(new FormControl(mob));
        }
      } else {
        this.mobiles.push(new FormControl(""));
      }
      // populate FormArray fields with data - or display one empty field
      if (this.existingOffer.email && this.existingOffer.email.length > 0) {
        for (var em of this.existingOffer.email) {
          this.emails.push(new FormControl(em));
        }
      } else {
        this.emails.push(new FormControl(""));
      }

    } else {
      this.offerForm = this.formBuilder.group({
        title: new FormControl("", Validators.required),
        title_ar: new FormControl("", Validators.required),
        description: new FormControl("", Validators.required),
        description_ar: new FormControl("", Validators.required),
        category: new FormControl(null, Validators.required),
        vendor: new FormControl(null, Validators.required),
        isPartnerHotel: new FormControl(false),
        highlights: new FormControl(""),
        highlights_ar: new FormControl(""),
        howToAvail: new FormControl(""),
        howToAvail_ar: new FormControl(""),
        isOccasional: new FormControl(false),
        expiryDate: new FormControl(""),
        isFeatured: new FormControl(false),
        enableMapSearch: new FormControl(true),
        hotelAmenitites: new FormControl([]),
        taxValue: new FormControl(""),
        taxValue_ar: new FormControl(""),
        currency: new FormControl(""),
        rooms: new FormArray([
          this.initRoom()
        ]),
        locations: new FormControl(""),
        contacts: new FormArray([new FormControl("")]),
        telephones: new FormArray([new FormControl("")]),
        mobiles: new FormArray([new FormControl("")]),
        emails: new FormArray([new FormControl("")]),
        website: new FormControl(""),
        discountCode: new FormControl("")
      });
      this.imageUrl = '';
      this.featuredImageUrl = '';
    }

    this.filteredVendors = this.offerForm.get('vendor')!.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      switchMap((value:string) => this.vendorService.getVendorsData(value, 0)),
      map(data => data),
    );

    this.filteredLocations = this.offerForm.get('locations')!.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      map(value => (typeof value === 'string' ? value : value.city)),
      switchMap(name => this._filterLocations(name)),
      map(data => data)
    );
  }

  private _filterLocations(name: string): Observable<Location[]> {
    if (this.offerForm.get('vendor')!.value) {
      const locations : Location[] = this.offerForm.get('vendor')!.value.locations;
      if (locations.length > 0) {
        if (name) {
          const filterValue = name.toLowerCase();
          return of(locations.filter(location => location.city.toLowerCase().includes(filterValue) || location.country.toLowerCase().includes(filterValue)));
        } else {
          return of(locations);
        }
      } else {
        return this.locationService.getLocationsData(name);
      }
    } else {
      return of([]);
    }
  }

  createVendorDialog() {
    this.offerForm.get('vendor')!.setValue('');
    this.createVendor.emit();
  }

  selectedVendor(event: MatAutocompleteSelectedEvent): void {
    const vendor : Vendor = event.option.value;
    this.addedLocations = [];
    vendor.locations?.forEach((loc) => {
      this.addedLocations.push(loc);
    });
    this.offerForm.get('locations')?.setValue('');
  }

  displayVendor(vendor: Vendor): string {
    return vendor ? vendor.name : '';
  }

  selectedLocation(event: MatAutocompleteSelectedEvent): void {
    const location: Location = event.option.value;
    if (this.addedLocations.includes(location)) {
      console.log('exists');
    } else {
      this.addedLocations.push(location);
    }
    this.locationInput.nativeElement.value = '';
    this.offerForm.get('locations')?.setValue('');
  }
  removeLocation(location: Location): void {
    const index = this.addedLocations.indexOf(location);
    if (index >= 0) {
      this.addedLocations.splice(index, 1);
    }
  }

  //remove image from the offer
  removeImage(){
    this.imageUrl= '';
  }
  //remove featured image
  removeFeaturedImage(){
    this.featuredImageUrl='';
  }
  // get list of currency codes from (currencies.json) local file
   getCurrenciesList()  {

    fetch('assets/Currency.json').then(res => res.json())
    .then(jsonData => {
      this.currencies = jsonData;

      this.currencies.forEach(currency => {
        this.currenciesList.push(currency.code);
      });
    });

    //  this.httpClient.get("assets/Currency.json").subscribe(data =>{
    //   this.currencies = data as [Currency];
    //   this.currenciesList = [];
    //       this.currencies.forEach(currency => {
    //     this.currenciesList.push(currency.code);
    //   });
    
    // })
   


  }

  getArabicCurreny(code:String) {
    var arabicCode ;
    this.currencies.forEach(currency => {
      if(currency.code == code)
      {
        arabicCode = currency.code_ar;
      }
  
    });

    return arabicCode;
  }

  //to set selected items (objects) in categories dropdown
  compareObjects(o1: Vendor, o2: Vendor): boolean {
    return o1 && o2 && o1.name === o2.name;
  }

  get contacts() {
    return this.offerForm.get('contacts') as FormArray;
  }
  addContact(){ // add field only if there are no empty fields
    var canAdd = true;
    for (let i = 0; i < this.contacts.length; i++) {
      if (this.contacts.at(i).value == ""){
        canAdd = false;
      }
    }
    if (canAdd)
      this.contacts.push(new FormControl(""));
  }
  removeContactAt(index: number){
    this.contacts.removeAt(index);
  }

  get telephones() {
    return this.offerForm.get('telephones') as FormArray;
  }
  addTelephone(){ // add field only if there are no empty fields
    var canAdd = true;
    for (let i = 0; i < this.telephones.length; i++) {
      if (this.telephones.at(i).value == ""){
        canAdd = false;
      }
    }
    if (canAdd)
      this.telephones.push(new FormControl(""));
  }
  removeTelephoneAt(index: number){
    this.telephones.removeAt(index);
  }

  get mobiles() {
    return this.offerForm.get('mobiles') as FormArray;
  }
  addMobile(){ // add field only if there are no empty fields
    var canAdd = true;
    for (let i = 0; i < this.mobiles.length; i++) {
      if (this.mobiles.at(i).value == ""){
        canAdd = false;
      }
    }
    if (canAdd)
      this.mobiles.push(new FormControl(""));
  }
  removeMobileAt(index: number){
    this.mobiles.removeAt(index);
  }

  get emails() {
    return this.offerForm.get('emails') as FormArray;
  }
  addEmail(){ // add field only if there are no empty fields
    var canAdd = true;
    for (let i = 0; i < this.emails.length; i++) {
      if (this.emails.at(i).value == ""){
        canAdd = false;
      }
    }
    if (canAdd)
      this.emails.push(new FormControl(""));
  }
  removeEmailAt(index: number){
    this.emails.removeAt(index);
  }

  initRoom(): FormGroup {
    return new FormGroup({
      name: new FormControl(''),
      name_ar: new FormControl(''),
      rates: new FormArray([
        this.initRate()
      ]),
      additionalInformation: new FormControl(''),
      additionalInformation_ar: new FormControl('')
    });
  }
  exportRoom(room: Room): FormGroup {
    return new FormGroup({
      name: new FormControl(room.name),
      name_ar: new FormControl(room.name_ar),
      rates: this.exportRates(room.rates),
      additionalInformation: new FormControl(room.additionalInformation),
      additionalInformation_ar: new FormControl(room.additionalInformation_ar)
    });
  }
  initRate(): FormGroup {
    return new FormGroup({
      season: new FormControl(''),
      season_ar: new FormControl(''),
      value: new FormControl(''),
    });
  }
  exportRates(rates: Rate[] | undefined): FormArray {
    const formArray = new FormArray([]);
    if (rates && rates.length > 0) {
      for (let rate of rates) {
        formArray.push(this.exportRate(rate));
      }
    }
    return formArray;
  }
  exportRate(rate: Rate): FormGroup {
    return new FormGroup({
      season: new FormControl(rate.season),
      season_ar: new FormControl(rate.season_ar),
      value: new FormControl(rate.value)
    });
  }

  getRooms() {
    return this.offerForm.get('rooms') as FormArray;
  }

  addRoomControl() {
    const rooms = this.getRooms();
    rooms.push(this.initRoom());
  }

  getRates(index: number) {
    return this.getRooms().controls[index].get('rates') as FormArray;
  }

  addRoomRate(index: number) {
    const rates = this.getRates(index);
    rates.push(this.initRate());
  }

  removeRoomAt(index: number) {
    const rooms = this.getRooms();
    rooms.removeAt(index);
  }

  removeRateAt(i: number, j: number) {
    const rates = this.getRates(i);
    rates.removeAt(j);
  }

  // dynamic form fields based on category type [ngIf in html]
  showRelatedFields(category: Category) {
    // if Hotel is selected, show hotel-related fields (isPartner checkbox + Hotel section fields)
    if (category.name == "Hotels" || category.name == "Hotel") {
      this.showHotelSection = true;
    }
    // if Non-Hotel is selected, hide hotel-related fields + clear their input if any
    else {
      this.showHotelSection = false;
      this.offerForm.get("isPartnerHotel")?.setValue(false);
      this.offerForm.get("taxValue")?.setValue('');
      this.offerForm.get("taxValue_ar")?.setValue('');
      this.offerForm.get("currency")?.setValue('');
      this.offerForm.get("hotelAmenitites")?.setValue([]);
      this.offerForm.get("rooms")?.reset();
    }
  }

  async submitForm() {
    this.startLoading.emit();
    const image = await this.uploadImage.triggerPost().toPromise();
    if (image) {
      this.imageUrl = image;
    }

    if (this.offerForm.get("isFeatured")?.value){
      const featured = await this.uploadFeaturedImage.triggerPost().toPromise();
      if (featured) {
        this.featuredImageUrl = featured;
      }
    }
    else {
      this.featuredImageUrl = '';
    }

    this.stopLoading.emit();
    
    //clearing arrays from empty records before sending to db
    for (let i = 0; i < this.telephones.length; i++) {
      if (this.telephones.at(i).value == ""){
        this.removeTelephoneAt(i);
      }
    }
    for (let i = 0; i < this.mobiles.length; i++) {
      if (this.mobiles.at(i).value == ""){
        this.removeMobileAt(i);
      }
    }
    for (let i = 0; i < this.emails.length; i++) {
      if (this.emails.at(i).value == ""){
        this.removeEmailAt(i);
      }
    }
    for (let i = 0; i < this.getRooms().length; i++) {
      const roomName = this.getRooms().at(i).value.name;
      if (roomName == "" || roomName == null){
        this.removeRoomAt(i);
      }
    }

    const expire: moment.Moment = this.offerForm.get('expiryDate')?.value;

    const newOffer: Offer = {
      title: this.offerForm.get("title")?.value,
      title_ar: this.offerForm.get("title_ar")?.value,
      description: this.offerForm.get("description")?.value,
      description_ar: this.offerForm.get("description_ar")?.value,
      category: this.offerForm.get("category")?.value,
      vendor: this.offerForm.get("vendor")?.value,
      isPartnerHotel: this.offerForm.get("isPartnerHotel")?.value,
      highlights: this.offerForm.get("highlights")?.value,
      highlights_ar: this.offerForm.get("highlights_ar")?.value,
      howToAvail: this.offerForm.get("howToAvail")?.value,
      howToAvail_ar: this.offerForm.get("howToAvail_ar")?.value,
      isOccasional: this.offerForm.get("isOccasional")?.value,
      expiryDate: expire ? expire.toDate() : undefined,
      image: this.imageUrl,
      isFeatured: this.offerForm.get("isFeatured")?.value,
      enableMapSearch: this.offerForm.get("enableMapSearch")?.value,
      featuredImage: this.featuredImageUrl,
      hotelAmenitites: this.offerForm.get("hotelAmenitites")?.value,
      rooms:  this.getRooms().value,
      locations:this.addedLocations,
      taxValue: this.offerForm.get("taxValue")?.value,
      taxValue_ar: this.offerForm.get("taxValue_ar")?.value,
      currency: this.offerForm.get("currency")?.value,
      currency_ar: this.getArabicCurreny(this.offerForm.get("currency")?.value),
      discountCode: this.offerForm.get("discountCode")?.value,
      contacts: this.offerForm.get("contacts")?.value,
      telephone: this.offerForm.get("telephones")?.value,
      mobile: this.offerForm.get("mobiles")?.value,
      email: this.offerForm.get("emails")?.value,
      website: this.offerForm.get("website")?.value,
    }

    if (this.isEdit) {
      newOffer._id = this.existingOffer._id;
      this.updateOffer.emit(newOffer);
    } else {
      this.createOffer.emit(newOffer);
    }

  }


}
