<div class="form-page-container">

    <button mat-mini-fab class="back-button" (click)="goBack.emit()">
    <mat-icon>arrow_back</mat-icon>
  </button>

    <form [formGroup]="categoryForm" (ngSubmit)="submitForm()" class="form-container">

        <div class="form-title">{{isEdit ? 'Update' : 'Add'}} Category</div>

        <mat-label class="field-label">Order</mat-label>
        <mat-form-field appearance="outline">
            <input matInput class="form-control" type="text" formControlName="order" />
        </mat-form-field>

        <mat-label class="field-label">Name</mat-label>
        <mat-form-field appearance="outline">
            <input matInput class="form-control" type="text" formControlName="name" />
        </mat-form-field>

        <mat-label class="field-label">Name_ar</mat-label>
        <mat-form-field appearance="outline">
            <input dir= rtl matInput class="form-control" type="text" formControlName="name_ar" />
        </mat-form-field>

        <mat-label class="field-label">Icon</mat-label>
        <div class="image-container">
            <img *ngIf="iconUrl" class="img" [src]="'/api' + iconUrl">
            <mat-icon class="delete-icon" *ngIf="iconUrl" (click)="removeIcon()">highlight_off</mat-icon>
        </div>
        <app-upload-image #uploadIcon></app-upload-image><br>

        <mat-label class="field-label">Image</mat-label>
        <div class="image-container">
            <img *ngIf="imageUrl" class="img" [src]="'/api' + imageUrl">
            <mat-icon class="delete-icon" *ngIf="imageUrl" (click)="removeImage()">highlight_off</mat-icon>
        </div>
        <app-upload-image #uploadImage></app-upload-image><br>

        <button mat-raised-button type="submit" class="big-button" [disabled]="!categoryForm.valid">{{isEdit ? 'Update' :
      'Add'}} Category</button>
    </form>
</div>