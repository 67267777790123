import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Category } from 'src/app/models/category.model';
import { ViewDialogComponent } from 'src/app/components/view-dialog/view-dialog.component';
import { Offer, Location } from 'src/app/models/offer.model';
import { Vendor } from 'src/app/models/vendor.model';
import { CategoriesService } from 'src/app/services/categories.service';
import { OffersService } from 'src/app/services/offers.service';
import { VendorsService } from 'src/app/services/vendors.service';
import { FeedbackService } from 'src/app/services/feedback.service';
import { Feedback } from 'src/app/models/feedback.model';
import { VendorFormDialogComponent } from 'src/app/components/vendor-form-dialog/vendor-form-dialog.component';
import { News } from 'src/app/models/news.model';
import { NewsService } from 'src/app/services/news.service';
import { User } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import { Version } from 'src/app/models/version.model';
import { VersionsService } from 'src/app/services/versions.service';

import * as fileSaver from 'file-saver';
import { LocationsService } from '../../services/locations.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {
  @Input() user_role!: string;
  @Output() logout = new EventEmitter<void>();

  isLoading = false;

  // Navigation Flags
  showCategoryTable = false;
  showVendorTable = false;
  showOfferTable = false;
  showFeedbackTable = false;
  showNewsTable = false;
  showUsersTable = false;
  showLocationTable = false;
  showVersionsTable = false;
  showCategoryForm = false;
  showVendorForm = false;
  showOfferForm = false;
  showNewsForm = false;
  showLocationForm = false;
  showUserForm = false;
  showVersionForm = false;
  title = "";

  // Search Form
  searchForm!: FormGroup;
  searchTerm = '';

  // Data store
  skip = 0;
  canLoad = true;
  isEdit = false;
  offers: Offer[] = [];
  categories: Category[] = [];
  users: User[] = [];
  vendors: Vendor[] = [];
  feedback: Feedback[] = [];
  news: News[] = [];
  versions: Version[] = [];
  existingCat!: Category;
  existingVendor!: Vendor;
  existingOffer!: Offer;
  existingNews!: News;
  existingUser!: User;
  existingLocation!: Location;
  existingVersion!: Version;
  locations: Location[] = [];


  constructor(
    private offerService: OffersService,
    private categoryService: CategoriesService,
    private vendorService: VendorsService,
    private feedbackService: FeedbackService,
    private newsService: NewsService,
    private locationService: LocationsService,
    private usersService: UsersService,
    private versionService: VersionsService,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      search: ['']
    });
    this.isLoading = true;
    this.openOfferList();
    this.fetchCategories();
    if (this.isAdmin()) {
      this.fetchUsers();
      this.fetchVersions();
      this.fetchLocations();
    }
  }

  private fetchCategories() {
    this.categoryService.getCategoriesData(this.searchTerm, this.skip).subscribe(
      data => {
        this.categories = data;
      },
      err => {
        this.handleError(err);
      }
    );
  }

  private fetchUsers() {
    this.usersService.getUsersData().subscribe(
      data => {
        this.users = data;
      },
      err => {
        this.handleError(err);
      }
    )
  }

  private fetchVersions() {
    this.versionService.getVersionsData().subscribe(
      data => {
        this.versions = data;
      },
      err => {
        this.handleError(err);
      }
    )
  }

  isAdmin(): boolean {
    return this.user_role == "Admin";
  }

  fetchVendors() {
    if (this.canLoad) {
      this.vendorService.getVendorsData(this.searchTerm, this.skip).subscribe(
        data => {
          if (data.length == 0) {
            this.canLoad = false;
          }
          this.vendors = [...this.vendors, ...data];
          this.skip = this.skip + data.length;
        },
        err => {
          this.handleError(err);
        }
      );
    }
  }

  fetchOffers() {
    if (this.canLoad) {
      this.offerService.getOffersData(this.searchTerm, this.skip).subscribe(
        data => {
          if (data.length == 0) {
            this.canLoad = false;
          }
          this.offers = [...this.offers, ...data];
          this.skip = this.skip + data.length;
          this.isLoading = false;
        },
        err => {
          this.handleError(err);
        }
      );
    }
  }

  fetchFeedback() {
    if (this.canLoad) {
      this.feedbackService.getFeedbackData(this.searchTerm, this.skip).subscribe(
        data => {
          if (data.length == 0) {
            this.canLoad = false;
          }
          this.feedback = [...this.feedback, ...data];
          this.skip = this.skip + data.length;
        },
        err => {
          this.handleError(err);
        }
      );
    }
  }

  fetchNews() {
    if (this.canLoad) {
      this.newsService.getNewsData(this.searchTerm, this.skip).subscribe(
        data => {
          if (data.length == 0) {
            this.canLoad = false;
          }
          this.news = [...this.news, ...data];
          this.skip = this.skip + data.length;
        },
        err => {
          this.handleError(err);
        }
      );
    }
  }

  fetchLocations() {
    if (this.canLoad) {
      this.locationService.getAllLocations(this.searchTerm,this.skip).subscribe(
        data => {
          if (data.length == 0) {
            this.canLoad = false;
          }
          this.locations = [...this.locations, ...data];
          this.skip = this.skip + data.length;
        },
        err => {
          this.handleError(err);
        }
      );
    }
  }

  private openSnackBar(message: string, action: string, duration: number = 2500) {
    this._snackBar.open(message, action, {
      duration: duration
    });
  }

  private handleError(err: HttpErrorResponse) {
    this.isLoading = false;
    console.error(err);
    if (err.status == 401) {
      this.openSnackBar("Unauthorized access", 'Dismiss');
      this.logout.emit();
    } else {
      if (err.error['error']) {
        this.openSnackBar(err.status + ": " + err.error['error'], 'Dismiss');
      } else {
        this.openSnackBar(err.status + ": Unknown server error", 'Dismiss');
      }
    }
  }

  submitSearch(): void {
    this.searchTerm = this.searchForm.get('search')?.value;
    if (this.showOfferTable) {
      this.resetData();
      this.fetchOffers();
    }
    if (this.showVendorTable) {
      this.resetData();
      this.fetchVendors();
    }
    if (this.showFeedbackTable) {
      this.resetData();
      this.fetchFeedback();
    }
    if (this.showNewsTable) {
      this.resetData();
      this.fetchNews();
    }
    if (this.showLocationTable) {
      this.resetData();
      this.fetchLocations();
    }
  }

  showNothing(): void {
    this.showCategoryTable = false;
    this.showVendorTable = false;
    this.showOfferTable = false;
    this.showFeedbackTable = false
    this.showNewsTable = false;
    this.showUsersTable = false;
    this.showLocationTable = false;
    this.showVersionsTable = false;
    this.showCategoryForm = false;
    this.showVendorForm = false;
    this.showOfferForm = false;
    this.showNewsForm = false;
    this.showLocationForm = false;
    this.showUserForm = false;
    this.showVersionForm = false;


    this.searchForm.setValue({ search: '' });
    this.searchTerm = '';
    this.resetData();
  }

  resetData(): void {
    this.skip = 0;
    this.canLoad = true;
    this.offers = [];
    this.vendors = [];
    this.feedback = [];
    this.locations = [];
    this.news = [];
  }

  openCategoryList(): void {
    this.showNothing();
    this.showCategoryTable = true;
    this.title = "Categories";
  }

  openCategoryForm(category?: Category) {
    this.showNothing();
    this.showCategoryForm = true;
    this.title = "Category Form";
    if (category) {
      this.isEdit = true;
      this.existingCat = category;
    } else {
      this.isEdit = false;
    }
  }

  openVendorList(): void {
    this.showNothing();
    this.showVendorTable = true;
    this.title = "Vendors";
    this.fetchVendors();
  }

  openVendorForm(vendor?: Vendor) {
    this.showNothing();
    this.showVendorForm = true;
    this.title = "Vendor Form";
    if (vendor) {
      this.isEdit = true;
      this.existingVendor = vendor;
    } else {
      this.isEdit = false;
    }
  }

  openVersionsList() {
    this.showNothing();
    this.showVersionsTable = true;
    this.title = "Versions";
  }

  openVersionForm(version?: Version) {
    this.showNothing();
    this.showVersionForm = true;
    this.title = "Version Form";
    if (version) {
      this.isEdit = true;
      this.existingVersion = version;
    } else {
      this.isEdit = false;
    }
  }

  openVendorDialog() {
    let dialogConfig: MatDialogConfig = {};
    this.matDialog.open(VendorFormDialogComponent, dialogConfig).afterClosed().subscribe(
      data => {
        if (data) {
          this.createVendor(data, false);
        }
      }
    );
  }

  openOfferList(): void {
    this.showNothing();
    this.showOfferTable = true;
    this.title = "Offers";
    this.fetchOffers();
  }

  openOfferForm(offer?: Offer) {
    this.showNothing();
    this.showOfferForm = true;
    this.title = "Offer Form";
    if (offer) {
      this.isEdit = true;
      this.existingOffer = offer;
    } else {
      this.isEdit = false;
    }
  }

  openFeedbackList(): void {
    this.showNothing();
    this.showFeedbackTable = true;
    this.title = "Feedback";
    this.fetchFeedback();
  }

  openNewsList(): void {
    this.showNothing();
    this.showNewsTable = true;
    this.title = "News";
    this.fetchNews();
  }

  openNewsForm(news?: News) {
    this.showNothing();
    this.showNewsForm = true;
    this.title = "News Form";
    if (news) {
      this.isEdit = true;
      this.existingNews = news;
    } else {
      this.isEdit = false;
    }
  }

  openUserForm(user?: User) {
    this.showNothing();
    this.showUserForm = true;
    this.title = "User Form";
    if (user) {
      this.isEdit = true;
      this.existingUser = user;
    } else {
      this.isEdit = false;
    }
  }





  openUsersList(): void {
    this.showNothing();
    this.showUsersTable = true;
    this.title = "Users";
  }

  openLocationList(): void {
    this.showNothing();
    this.showLocationTable = true;
    this.title = "Locations";
    this.fetchLocations();
  }

  openLocationForm(location?: Location) {
    this.showNothing();
    this.showLocationForm = true;
    this.title = "Location Form";

    if (location) {
      this.isEdit = true;
      this.existingLocation = location;
    } else {
      this.isEdit = false;
    }
  }

  reviewFeedback(feedback: Feedback): void {
    feedback.reviewed = !feedback.reviewed;
    this.feedbackService.updateFeedback(feedback).subscribe(
      data => {
        this.openSnackBar("Feedback updated successfully", "Dismiss");
        this.feedback = this.feedback.map((item) => {
          if (item._id == data._id) {
            return data;
          } else {
            return item;
          }
        });
      },
      err => {
        this.handleError(err);
      }
    )
  }

  downloadFeedback(): void {
    this.feedbackService.getFeedbackCsv().subscribe(
      data => {
        console.log('csv', data);
        var blob = new Blob([data.csv], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob,"feedback.csv");
        // window.open(url);
      },
      err => {
        this.handleError(err);
      }
    )
  }

  downloadVendors(): void {
    this.vendorService.getVendorsCsv().subscribe(
      data => {
        console.log('csv', data);
        var blob = new Blob([data.csv], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob,"vendors.csv");
      },
      err => {
        this.handleError(err);
      }
    )
  }
  downloadOffers(): void {
    this.offerService.getOffersCsv().subscribe(
      data => {
        console.log('csv', data);
        var blob = new Blob([data.csv], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob,"offers.csv");
      },
      err => {
        this.handleError(err);
      }
    )
  }

  UpdateOffersFromCSV(): void {

    this.offerService.getOffersCsv().subscribe(
      data => {
        console.log('csv', data);
        var blob = new Blob([data.csv], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob,"offers.csv");
      },
      err => {
        this.handleError(err);
      }
    )
  }



  createCategory(category: Category): void {
    this.categoryService.createCategory(category).subscribe(
      data => {
        this.openSnackBar("Category created successfully", "Dismiss");
        this.fetchCategories();
        this.openCategoryList();
      },
      err => {
        this.handleError(err);
      }
    );
  }

  updateCategory(category: Category): void {
    this.categoryService.updateCategory(category).subscribe(
      data => {
        this.openSnackBar("Category updated successfully", "Dismiss");
        this.fetchCategories();
        this.openCategoryList();
      },
      err => {
        this.handleError(err);
      }
    );
  }
  deleteCategory(category: Category): void {
    this.categoryService.deleteCategory(category).subscribe(
      data => {
        this.openSnackBar("Category deleted successfully", "Dismiss");
        this.fetchCategories();
      },
      err => {
        this.handleError(err);
      }
    )
  }

  createVersion(version: Version): void {
    this.versionService.postVersion(version).subscribe(
      data => {
        this.openSnackBar("Version created successfully", "Dismiss");
        this.fetchVersions();
        this.openVersionsList();
      },
      err => {
        this.handleError(err);
      }
    );
  }
  updateVersion(version: Version): void {
    this.versionService.updateVersions(version).subscribe(
      data => {
        this.openSnackBar("Version updated successfully", "Dismiss");
        this.fetchVersions();
        this.openVersionsList();
      },
      err => {
        this.handleError(err);
      }
    );
  }
  deleteVersion(version: Version): void {
    this.versionService.deleteVersions(version).subscribe(
      data => {
        this.openSnackBar("Version deleted successfully", "Dismiss");
        this.fetchVersions();
      },
      err => {
        this.handleError(err);
      }
    )
  }

  createNews(news: News): void {
    this.newsService.postNewsData(news).subscribe(
      data => {
        this.openSnackBar("News created successfully", "Dismiss");
        this.openNewsList();
      },
      err => {
        this.handleError(err);
      }
    );
  }
  updateNews(news: News): void {
    this.newsService.updateNews(news).subscribe(
      data => {
        this.openSnackBar("News updated successfully", "Dismiss");
        this.openNewsList();

      },
      err => {
        this.handleError(err);
      }
    );
  }

  updateLocation(location: Location): void {

    this.locationService.updateLocation(location).subscribe(
      data => {
        this.openSnackBar("Location updated successfully", "Dismiss");
        this.openLocationList();

      },
      err => {
        this.handleError(err);
      }
    );
  }


  deleteNews(news: News): void {
    this.newsService.deleteNews(news).subscribe(
      data => {
        this.openSnackBar("Data deleted successfully", "Dismiss");
        this.news = this.news.filter((item) => item._id != data._id);
        this.skip = this.skip - 1;
      },
      err => {
        this.handleError(err);
      }
    )
  }
  activateNews(news: News): void {
    news.isActive = !news.isActive;
    this.newsService.updateNews(news).subscribe(
      data => {
        this.openSnackBar("News updated successfully", "Dismiss");
        this.news = this.news.map((item) => {
          if (item._id == data._id) {
            return data;
          } else {
            return item;
          }
        });
      },
      err => {
        this.handleError(err);
      }
    );
  }


  createVendor(vendor: Vendor, redirect: boolean = true): void {
    this.isLoading = true;
    this.vendorService.postVendorsData(vendor).subscribe(
      data => {
        this.isLoading = false;
        this.openSnackBar("Vendor created successfully", "Dismiss");
        if (redirect) {
          this.openVendorList();
        }
      },
      err => {
        this.handleError(err);
      }
    );
  }

  updateVendor(vendor: Vendor): void {
    this.isLoading = true;
    this.vendorService.updateVendor(vendor).subscribe(
      data => {
        this.isLoading = false;
        this.openSnackBar("Vendor updated successfully", "Dismiss");
        this.openVendorList();
      },
      err => {
        this.handleError(err);
      }
    );
  }

  activateVendor(vendor: Vendor): void {
    vendor.isActive = !vendor.isActive;
    this.vendorService.updateVendor(vendor).subscribe(
      data => {
        this.openSnackBar("Vendor updated successfully", "Dismiss");
        this.vendors = this.vendors.map((item) => {
          if (item._id == data._id) {
            return data;
          } else {
            return item;
          }
        });
      },
      err => {
        this.handleError(err);
      }
    );
  }

  deleteVendor(vendor: Vendor): void {
    this.vendorService.deleteVendor(vendor).subscribe(
      data => {
        this.openSnackBar("Vendor deleted successfully", "Dismiss");
        this.vendors = this.vendors.filter((item) => item._id != data._id);
        this.skip = this.skip - 1;
      },
      err => {
        this.handleError(err);
      }
    );
  }

  private openViewDialog(data: { offer?: Offer, category?: Category, vendor?: Vendor , news?: News, user?: User, location?: Location}): void {
    let dialogConfig = new MatDialogConfig();

    if (data.offer) {
      dialogConfig = {
        data: {
          offer: data.offer
        }
      }
    } else if (data.vendor) {
      dialogConfig = {
        data: {
          vendor: data.vendor
        }
      }
    } else if (data.category) {
      dialogConfig = {
        data: {
          category: data.category
        }
      }
    }else if (data.news) {
      dialogConfig = {
        data: {
          news: data.news
        }
      }
    }else if (data.user) {
      dialogConfig = {
        data: {
          user: data.user
        }
      }
    }
    else if (data.location) {
      dialogConfig = {
        data: {
          location: data.location
        }
      }
    }
    this.matDialog.open(ViewDialogComponent, dialogConfig);
  }

  viewOffer(offer: Offer): void {
    this.openViewDialog({ offer: offer });
  }

  viewCategory(category: Category): void {
    this.openViewDialog({ category: category });
  }

  viewVendor(vendor: Vendor): void {
    this.openViewDialog({ vendor: vendor });
  }

  viewNews(news: News): void {
    this.openViewDialog({ news: news });
  }
  viewLocation(loaction: Location): void {
    this.openViewDialog({ location: loaction });
  }

  viewUsers(user: User): void {
    this.openViewDialog({ user: user });
  }

  createOffer(offer: Offer): void {
    this.isLoading = true;
    this.offerService.createOffer(offer).subscribe(
      data => {
        this.isLoading = false;
        this.openSnackBar("Offer created successfully", "Dismiss");
        this.openOfferList();
      },
      err => {
        this.handleError(err);
      }
    );
  }

  updateOffer(offer: Offer): void {
    this.isLoading = true;
    this.offerService.updateOffer(offer).subscribe(
      data => {
        this.isLoading = false;
        this.openSnackBar("Offer updated successfully", "Dismiss");
        this.openOfferList();
      },
      err => {
        this.handleError(err);
      }
    );
  }

  activateOffer(offer: Offer): void {
    offer.isActive = !offer.isActive;
    this.offerService.updateOffer(offer).subscribe(
      data => {
        this.openSnackBar("Offer updated successfully", "Dismiss");
        this.offers = this.offers.map((item) => {
          if (item._id == data._id) {
            return data;
          } else {
            return item;
          }
        });
      },
      err => {
        this.handleError(err);
      }
    );
  }

  deleteOffer(offer: Offer): void {
    this.offerService.deleteOffer(offer).subscribe(
      data => {
        this.openSnackBar("Offer deleted successfully", "Dismiss");
        this.offers = this.offers.filter((item) => item._id != data._id);
        this.skip = this.skip - 1;
      },
      err => {
        this.handleError(err);
      }
    );
  }

  createLocation(location: Location): void {
    this.locationService.createLocation(location).subscribe(
      data => {
        this.openSnackBar("Location created successfully", "Dismiss");
        this.openLocationList();
      },
      err => {
        this.handleError(err);
      }
    );
  }

  deleteLocation(location: Location): void {
    this.locationService.deleteLocation(location).subscribe(
      data => {
        this.openSnackBar("Location deleted successfully", "Dismiss");
        this.openLocationList();
      },
      err => {
        this.handleError(err);
      }
    );
  }

  populateLocations(): void {
    this.isLoading = true;
    this.locationService.populateLocations().subscribe(
      data => {
        console.log(data);
        this.isLoading = false;
      },
      err => {
        this.handleError(err);
      }
    )
  }

  createUser(user: User): void {
    this.usersService.createUser(user).subscribe(
      data => {
        this.openSnackBar("User created successfully", "Dismiss");
        this.fetchUsers();
        this.openUsersList();
      },
      err => {
        this.handleError(err);
      }
    );
  }

  updateUser(user: User): void {
    this.usersService.updateUser(user).subscribe(
      data => {
        this.openSnackBar("User updated successfully", "Dismiss");
        this.openUsersList();
      },
      err => {
        this.handleError(err);
      }
    );
  }

  deleteUser(user: User): void {
    this.usersService.deleteUser(user).subscribe(
      data => {
        this.openSnackBar("User deleted successfully", "Dismiss");
        this.fetchUsers();
      },
      err => {
        this.handleError(err);
      }
    )
  }







}
