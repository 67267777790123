import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Category } from 'src/app/models/category.model';
import { UploadImageComponent } from '../upload-image/upload-image.component';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss']
})
export class CategoryFormComponent implements OnInit {
  @Input() isEdit = false;
  @Input() existingCat!: Category;
  @Output() createCategory = new EventEmitter<Category>();
  @Output() updateCategory = new EventEmitter<Category>();
  @Output() startLoading = new EventEmitter<void>();
  @Output() stopLoading = new EventEmitter<void>();
  @Output() goBack = new EventEmitter<void>();

  @ViewChild('uploadIcon')
  private uploadIcon!: UploadImageComponent;
  @ViewChild('uploadImage')
  private uploadImage!: UploadImageComponent;

  categoryForm!: FormGroup;
  iconUrl = '';
  imageUrl = '';

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.categoryForm = this.formBuilder.group({
      name: new FormControl(this.isEdit ? this.existingCat.name : '', Validators.required),
      name_ar: new FormControl(this.isEdit ? this.existingCat.name_ar : '', Validators.required),
      order: new FormControl(this.isEdit ? this.existingCat.order : null)
    });
    this.iconUrl = this.isEdit ? this.existingCat.icon : '';
    this.imageUrl = this.isEdit ? this.existingCat.image : '';
  }

  async submitForm() {

    this.startLoading.emit();

    const icon = await this.uploadIcon.triggerPost().toPromise();
    if (icon) {
      this.iconUrl = icon;
    }
    const image = await this.uploadImage.triggerPost().toPromise();
    if (image) {
      this.imageUrl = image;
    }

    this.stopLoading.emit();

    const newCat: Category = {
      name: this.categoryForm.get("name")?.value,
      name_ar: this.categoryForm.get("name_ar")?.value,
      icon: this.iconUrl,
      image: this.imageUrl,
      order: this.categoryForm.get("order")?.value
    }

    if (this.isEdit) {
      newCat._id = this.existingCat._id;
      this.updateCategory.emit(newCat);
    } else {
      this.createCategory.emit(newCat);
    }
  }

   //remove image from category
   removeImage(){
    this.imageUrl= '';
  }
   //remove icon from category
   removeIcon(){
    this.iconUrl= '';
  }
}
