import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Category } from 'src/app/models/category.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';



@Component({
  selector: 'app-categories-table',
  templateUrl: './categories-table.component.html',
  styleUrls: ['./categories-table.component.scss']
})


export class CategoriesTableComponent implements OnInit {
  @Input() categories: Category[] = [];
  @Input() searchTerm = '';
  @Output() viewCategory =new EventEmitter<Category>();
  @Output() editCategory = new EventEmitter<Category>();
  @Output() deleteCategory = new EventEmitter<Category>();
  
  displayedColumns: string[] = ['Order', 'Name','Name_ar', 'Icon', 'Image', 'Actions'];

  constructor(private matDialog: MatDialog) { }

  ngOnInit(): void {
  }

  getFilteredList(): Category[] {
    return this.categories.filter((cat) => {
      return cat.name.toLowerCase().includes(this.searchTerm.toLowerCase());
    });
  }
  // getting clicked category ID for other components' consumption
  editCategoryClicked(category: Category) {
    this.editCategory.emit(category);
  }

  viewCategoryData(category: Category) {
    this.viewCategory.emit(category);
  }
  
  deleteCategoryClicked(category: Category) {
    const dialogConfig: MatDialogConfig = {
      data: {
        message: "Are you sure you want to delete?"
      }
    };
    this.matDialog.open(ConfirmDialogComponent, dialogConfig).afterClosed().subscribe(
      result => {
        if (result) {
          this.deleteCategory.emit(category);
        } 
      }
    );
  }
}