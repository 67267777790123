<table mat-table [dataSource]="getFilteredList()" class="data-table">

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="Order">
        <th mat-header-cell *matHeaderCellDef>Display Order</th>
        <td mat-cell *matCellDef="let category">{{category.order}}</td>
    </ng-container>

    <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>Category Name</th>
        <td mat-cell *matCellDef="let category">{{category.name}}</td>
    </ng-container>

    <ng-container matColumnDef="Name_ar">
        <th mat-header-cell *matHeaderCellDef>Arabic Name</th>
        <td mat-cell *matCellDef="let category">{{category.name_ar}}</td>
    </ng-container>

    <ng-container matColumnDef="Icon">
        <th mat-header-cell *matHeaderCellDef>Icon</th>
        <td mat-cell *matCellDef="let category">
            <img [src]="'/api/' + category.icon" class="row-img">
        </td>
    </ng-container>

    <ng-container matColumnDef="Image">
        <th mat-header-cell *matHeaderCellDef>Image</th>
        <td mat-cell *matCellDef="let category">
            <img [src]="'/api/' + category.image" class="row-img">
        </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let category">

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)=viewCategoryData(category)>
                    <mat-icon>assignment</mat-icon>
                    <span>View</span>
                </button>
                <button mat-menu-item (click)=editCategoryClicked(category)>
                    <mat-icon>mode_edit</mat-icon>
                    <span>Edit</span>
                </button>
                <button mat-menu-item (click)="deleteCategoryClicked(category)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                </button>
            </mat-menu>
        </td>
    </ng-container>
</table>