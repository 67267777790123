import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpUtilsService } from 'src/app/services/http-utils.service';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  @Output() uploadSuccess= new EventEmitter<string>();

  constructor(private utilService: HttpUtilsService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  files: File[] = [];

  private openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  onSelect(event: NgxDropzoneChangeEvent) {
    this.files = []; // we want a single upload
    this.files.push(...event.addedFiles);
  }

  triggerPost(): Observable<string> {
    if (this.files.length == 0) return of('');

    const formData = new FormData();
    formData.append("image", this.files[0]);

    return this.utilService.uploadImage(formData).pipe(
      map(res => {
        return res.url;
      }),
      catchError(err => {
        console.error("Error uploading image");
        this.openSnackBar(err.status + ": Error uploading image", 'Dismiss');
        return '';
      })
    );
  }

  onRemove(event: File) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  shortName(name: string): string {
    return name.substring(0, 15);
  }

}
