import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Offer } from 'src/app/models/offer.model';
import { updateOfferReq } from 'src/app/models/updateOfferReq.model';
import { Room } from 'src/app/models/offer.model';
import { Rate } from 'src/app/models/offer.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { OffersService } from 'src/app/services/offers.service';
import * as csv from 'csvtojson';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Currency } from 'src/app/models/curreny.model';
import envs from 'env.json';

@Component({
  selector: 'app-offers-table',
  templateUrl: './offers-table.component.html',
  styleUrls: ['./offers-table.component.scss']
})


export class OffersTableComponent implements OnInit {
  @Input() offers: Offer[] = [];
  @Input() canLoad = false;
  @Output() viewOffer = new EventEmitter<Offer>();
  @Output() editOffer = new EventEmitter<Offer>();
  @Output() activateOffer = new EventEmitter<Offer>();
  @Output() deleteOffer = new EventEmitter<Offer>();
  @Output() downloadCSV = new EventEmitter<void>();
  @Output() UploadCSV = new EventEmitter<FileList>();
  @Output() loadMore = new EventEmitter<void>();
  displayedColumns: string[] = ['Image', 'Title', 'Description', 'Category', 'Vendor', 'Expiry Date', 'Status', 'Actions'];

  constructor(private matDialog: MatDialog, private offerService: OffersService, private _snackBar: MatSnackBar) { }
  arrOffers: Offer[] = [];
  currenciesList: String[] = [];
  currencies: Currency []= [];
  @ViewChild('fileImportInput') fileImportInput: any;
  header: boolean = false;
  showbtnUpdate: boolean = false;
  showbtnUpload: boolean = false;
  uploadBtnTitle = "Upload"

   

  ngOnInit(): void {
    this.showbtnUpload = envs.showbtnUpload;
    this.getCurrenciesList();
 
  }


  activateStatus(offer: Offer) {
    this.activateOffer.emit(offer);
  }

  deleteOfferClicked(offer: Offer) {
    const dialogConfig: MatDialogConfig = {
      data: {
        message: "Are you sure you want to delete?"
      }
    };
    this.matDialog.open(ConfirmDialogComponent, dialogConfig).afterClosed().subscribe(
      result => {
        if (result) {
          this.deleteOffer.emit(offer);
        }
      }
    );
  }

  editOfferClicked(offer: Offer) {
    this.editOffer.emit(offer);
  }

  viewOfferData(offer: Offer) {
    this.viewOffer.emit(offer);
  }



  fileChangeListener($event: any): void {
    this.showbtnUpdate = false;
  
    const files = $event.srcElement.files;
    let file: File = files.item(0);
    this.uploadBtnTitle = file.name;
    this.loadCsvFile(file, ',').then(json => {
      let jsondata = json;
    });


  }


  public loadCsvFile(file: File, delimiter: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onerror = (e: any) => {
        reject(fileReader.error);
      }

      fileReader.onload = (e: any) => {

        this.textToCsv(e.target.result);
      }

      fileReader.readAsText(file);
    })
  }




  textToCsv(text: string) {

    const hds = new Array();
    const rows = new Array();
    const csvjson = csv({ noheader: true })
      .fromString(text)
      .then(jsonArr => {
        jsonArr.forEach((row, index) => {
          if (index === 0) {
            Object.keys(row).map(id => hds.push(row[id].toLocaleLowerCase()));
          } else {
            row = Object.keys(row).map(id => row[id]);
            if (row.length !== hds.length) {
              console.log('Ops looks like you have an empty space');
            }
            const dicrows: { [index: string]: any } = {};
            hds.forEach((head, i) => {
              dicrows[head] = row[i];
            });
            rows.push(dicrows);
          }
        });
        var arrayJson = JSON.parse(JSON.stringify(rows))
        this.arrOffers = [];

        for (let i = 0; i < arrayJson.length; i++) {

          var off = this.arrOffers.find(c => c._id === arrayJson[i]._id);
          if (off === undefined) {

            if (arrayJson[i].category === "Hotels") {
              var arrRates: Rate[] = [];
              if (arrayJson[i].seasonid !== null && arrayJson[i].seasonid !== 0) {
                const newRate: Rate = {
                  _id: arrayJson[i].seasonid,
                  season: arrayJson[i].seasonname,
                  season_ar: arrayJson[i].seasonname_ar,
                  value: arrayJson[i].seasonvalue,
                }

                arrRates.push(newRate);
              }

              var arrRooms: Room[] = [];
              if (arrayJson[i].roomid !== null && arrayJson[i].roomid.length !== 0) {
                const newRoom: Room = {
                  _id: arrayJson[i].roomid,
                  name: arrayJson[i].roomname,
                  name_ar: arrayJson[i].roomname_ar,
                  additionalInformation_ar: arrayJson[i].additionalinformation_ar,
                  rates: arrRates,
                }

                arrRooms.push(newRoom);
              }

              const newOffer: Offer = {
                _id: arrayJson[i]._id,
                title: arrayJson[i].title,
                title_ar: arrayJson[i].title_ar,
                description_ar: arrayJson[i].description_ar,
                description: arrayJson[i].description,
                category: arrayJson[i].category,
                highlights_ar: arrayJson[i].highlights_ar,
                currency_ar: this.getArabicCurreny(arrayJson[i].currency),
                howToAvail_ar: arrayJson[i].howtoavail_ar,
                taxValue_ar: arrayJson[i].taxvalue_ar,
                rooms: arrRooms,
              }

              this.arrOffers.push(newOffer);

            }
            else {
              const newOffer: Offer = {
                _id: arrayJson[i]._id,
                title: arrayJson[i].title,
                title_ar: arrayJson[i].title_ar,
                description_ar: arrayJson[i].description_ar,
                description: arrayJson[i].description,
                category: arrayJson[i].category,
                highlights_ar: arrayJson[i].highlights_ar,
                currency_ar: this.getArabicCurreny(arrayJson[i].currency),
                howToAvail_ar: arrayJson[i].howtoavail_ar,
                taxValue_ar: arrayJson[i].taxvalue_ar,
              }

              this.arrOffers.push(newOffer);

            }


          }
          else {
            let arrRooms = off.rooms;
            if (arrRooms !== undefined) {
              var existsRoom = arrRooms.find(c => c._id === arrayJson[i].roomid);
              if (existsRoom === undefined) {
                var arrRates: Rate[] = [];
                if (arrayJson[i].seasonid !== null && arrayJson[i].seasonid.length !== 0) {
                  const newRate: Rate = {
                    _id: arrayJson[i].seasonid,
                    season: arrayJson[i].seasonname,
                    season_ar: arrayJson[i].seasonname_ar,
                    value: arrayJson[i].seasonvalue,
                  }

                  arrRates.push(newRate);
                }

                const newRoom: Room = {
                  _id: arrayJson[i].roomid,
                  name: arrayJson[i].roomname,
                  name_ar: arrayJson[i].roomname_ar,
                  additionalInformation: arrayJson[i].additionalinformation,
                  additionalInformation_ar: arrayJson[i].additionalinformation_ar,
                  rates: arrRates,
                }



                off.rooms?.push(newRoom);

              }
              else {
                if (arrayJson[i].seasonid !== null && arrayJson[i].seasonid.length !== 0) {
                  const newRate: Rate = {
                    _id: arrayJson[i].seasonid,
                    season: arrayJson[i].seasonname,
                    season_ar: arrayJson[i].seasonname_ar,
                    value: arrayJson[i].seasonvalue,
                  }

                  existsRoom.rates.push(newRate);
                }

              }

            }


          }

        }

        console.log(this.arrOffers);
        this.showbtnUpdate = true
      
        return this.arrOffers;
      });


  }


  getArabicCurreny(code: String) {
    var arabicCode;
   this.currencies.forEach(currency => {
      if (currency.code === code) {
        arabicCode = currency.code_ar;
      }

    });

    return arabicCode;
  }
  getCurrenciesList() {

    fetch('assets/Currency.json').then(res => res.json())
    .then(jsonData => {
      this.currencies = jsonData;

      this.currencies.forEach(currency => {
        this.currenciesList.push(currency.code);
      });
    });

   
  }





  fetchOffers() {
    if (this.showbtnUpdate) {
      let obj: updateOfferReq = {
        offers: this.arrOffers,
      };
      this.offerService.updateOfferFromCSV(obj).subscribe(
        data => {

          this.showbtnUpdate = false;
          this.uploadBtnTitle = "Upload";
          this.openSnackBar("sucessfull update", 'Dismiss');


        },
        err => {
          this.handleError(err);
        }
      );
    }
  }


  private handleError(err: HttpErrorResponse) {
    //this.isLoading = false;
    console.error(err);
    if (err.status == 401) {
      this.openSnackBar("Unauthorized access", 'Dismiss');
      //this.logout.emit();
    } else {
      if (err.error['error']) {
        this.openSnackBar(err.status + ": " + err.error['error'], 'Dismiss');
      } else {
        this.openSnackBar(err.status + ": Unknown server error", 'Dismiss');
      }
    }
  }

  private openSnackBar(message: string, action: string, duration: number = 2500) {
    this._snackBar.open(message, action, {
      duration: duration
    });
  }

}

