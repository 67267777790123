import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Category } from 'src/app/models/category.model';
import { Vendor } from 'src/app/models/vendor.model';

@Component({
  selector: 'app-vendor-form-dialog',
  templateUrl: './vendor-form-dialog.component.html',
  styleUrls: ['./vendor-form-dialog.component.scss']
})
export class VendorFormDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<VendorFormDialogComponent>) {}

  ngOnInit(): void {
  }

  createVendor(vendor: Vendor) {
    this.dialogRef.close(vendor);
  }

}
