import { Component, OnInit, EventEmitter, Input, Output} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Location } from 'src/app/models/offer.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-location-table',
  templateUrl: './location-table.component.html',
  styleUrls: ['./location-table.component.scss']
})
export class LocationTableComponent implements OnInit {

  @Input() canLoad = false;
  @Input() Locationlist: Location[] = [];
  @Output() viewLocation =new EventEmitter<Location>();
  @Output() editLocation = new EventEmitter<Location>();
  @Output() deleteLocation = new EventEmitter<Location>();
  @Output() loadMore = new EventEmitter<void>();


  displayedColumns: string[] = ['Country','City','City Arabic', 'Actions'];


  constructor(private matDialog: MatDialog) { }

  ngOnInit() {
  }


  editLocationClicked(locat: Location) {
    this.editLocation.emit(locat);
  }

  
  deleteNewsClicked(locat: Location) {
    const dialogConfig: MatDialogConfig = {
      data: {
        message: "Are you sure you want to delete?"
      }
    };
    this.matDialog.open(ConfirmDialogComponent, dialogConfig).afterClosed().subscribe(
      result => {
        if (result) {
          this.deleteLocation.emit(locat);
        } 
      }
    );
  }


}
