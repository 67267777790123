import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Category } from 'src/app/models/category.model';
import { Offer } from 'src/app/models/offer.model';
import { Vendor } from 'src/app/models/vendor.model';
import { News } from 'src/app/models/news.model';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-view-dialog',
  templateUrl: './view-dialog.component.html',
  styleUrls: ['./view-dialog.component.scss']
})
export class ViewDialogComponent implements OnInit {

  offer!: Offer;
  category!: Category;
  vendor!: Vendor;
  news!: News;
  user!: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {offer: Offer, category: Category, vendor: Vendor, news: News, user: User},
    public dialogRef: MatDialogRef<ViewDialogComponent>)
  {
    if (data.offer) {
      this.offer = data.offer;
    } else if (data.vendor) {
      this.vendor = data.vendor;
    } else if (data.category) {
      this.category = data.category;
    }else if (data.news) {
      this.news = data.news;
    }else if (data.user) {
      this.user = data.user;
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}
