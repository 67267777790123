<div class="row">
    <div class="view-title">News Details</div>
</div>

<div class="view-dialog">
    <div class="dialog-container">
        <div class="row">
            <div class="view-label">ID: </div>
            <div class="view-data">{{news?._id}}</div>
        </div>
        <div class="row">
            <div class="view-label"> Title: </div> 
            <div class="view-data">{{news?.title}}</div>
        </div>
        <div class="row">
            <div class="view-label"> Description: </div> 
            <div class="view-data">{{news?.description}}</div>
        </div>
        <div class="row">
            <div class="view-label"> Image: </div> 
            <img class="view-data view-img" [src]="'/api' + news?.image">
        </div>
        <div class="row">
            <div class="view-label">Status: </div>
            <div class="view-data">{{news?.isActive}}</div>
        </div>
    </div>

</div>