<ng-container *ngIf="!isLoggedIn && !isOtp">
  <app-login (login)="login($event)"></app-login>
</ng-container>

<ng-container *ngIf="!isLoggedIn && isOtp">
  <app-otp [phone]="user.phone" (submitOtp)="submitOtp($event)"></app-otp>
</ng-container>

<ng-container *ngIf="isLoggedIn">
  <app-container [user_role]="user_role" (logout)="logout()"></app-container>
</ng-container>
