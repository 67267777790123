import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Vendor } from '../models/vendor.model';
import { HttpUtilsService } from './http-utils.service';

@Injectable({
  providedIn: 'root' // service is registered in root module (app.module.ts)
})
export class VendorsService {

  constructor(private http: HttpClient, private utils: HttpUtilsService) { }

  getVendorsData(searchTerm: string, skip: number): Observable<Vendor[]> {
    return this.http.get<Vendor[]>('/api/cms-vendors?query=' + searchTerm + '&skip=' + skip, this.utils.httpOptions);
  }
  getVendorsCsv(): Observable<{csv: string}> {
    return this.http.get<{csv: string}>('/api/vendors-csv', this.utils.httpOptions);
  }
  postVendorsData(vendor: Vendor): Observable<Vendor> {
    return this.http.post<Vendor>('/api/create-vendor', vendor, this.utils.httpOptions);
  }

  updateVendor(vendor: Vendor) {
    return this.http.post<Vendor>('/api/update-vendor/' + vendor._id, vendor, this.utils.httpOptions);
  }

  deleteVendor(vendor: Vendor): Observable<Vendor> {
    return this.http.post<Vendor>('/api/delete-vendor/' + vendor._id, {}, this.utils.httpOptions);
  }
}