import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  @Input() isEdit = false;
  @Input() existingUser!: User;
  @Output() createUser = new EventEmitter<User>();
  @Output() updateUser = new EventEmitter<User>();
  @Output() goBack = new EventEmitter<void>();

  userForm!: FormGroup;

  roles = ['Admin', 'Personnel', 'GTS'];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if (this.isEdit) {
      this.userForm = this.formBuilder.group({
        username: new FormControl(this.existingUser.username, Validators.required),
        password: new FormControl("", Validators.required),
        role: new FormControl(this.existingUser.role, Validators.required),
        phone: new FormControl(this.existingUser.phone, Validators.required),
        email: new FormControl(this.existingUser.email, Validators.required)
      });
    } else {
      this.userForm = this.formBuilder.group({
        username: new FormControl("", Validators.required),
        password: new FormControl("", Validators.required),
        role: new FormControl("", Validators.required),
        phone: new FormControl("", Validators.required),
        email: new FormControl("", Validators.required)
      });
    }
  }

  submitForm() {

    const newUser: User = {
      username: this.userForm.get("username")?.value,
      password: this.userForm.get("password")?.value,
      phone: this.userForm.get("phone")?.value,
      role: this.userForm.get("role")?.value,
      email: this.userForm.get("email")?.value
    }

    if (this.isEdit) {
      newUser._id = this.existingUser._id;
      this.updateUser.emit(newUser);
    } else {
      this.createUser.emit(newUser);
    }
  }

}

