import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpUtilsService {

  token = '';

  get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization' : this.token,
      })
    };
  }

  constructor(private http: HttpClient) { }

  uploadImage(formData: FormData): Observable<{url: string}> {
    const uploadOptions = {
      headers: new HttpHeaders({
        'authorization' : this.token,
        'responseType': 'text',
      })
    };
    return this.http.post<{url: string}>('/api/upload', formData, uploadOptions);
  }
}
