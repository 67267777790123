import { Component, Input, OnInit } from '@angular/core';
import { News } from 'src/app/models/news.model';

@Component({
  selector: 'app-view-news',
  templateUrl: './view-news.component.html',
  styleUrls: ['./view-news.component.scss']
})
export class ViewNewsComponent implements OnInit {
  @Input() news!: News;

  constructor() { }

  ngOnInit(): void {
  }

}